import React, { useState, useRef } from "react";

const PhotoLibrary = ({
  images,
  handleFaceImageSelect,
  renderLoadingPlaceholders,
}) => {
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFace, setSelectedFace] = useState(null);

  const handleImageClick = (img) => {
    setSelectedFace(img);
    handleFaceImageSelect(img);
  };

  return (
    <div className="rounded-lg bg-grayBG2 py-2 flex items-center justify-center ">
      <div
        className="grid grid-cols-3 h-[90px] gap-4 overflow-y-auto"
        style={{
          maxHeight: "300px",
          scrollbarWidth: "thin",
          scrollbarColor: "#666 #222226",
        }}
      >
        {images && images.length > 0 ? (
          images.map((img, index) => (
            <div
              key={index}
              className={`aspect-square w-[80px] h-[80px] relative rounded-lg overflow-hidden bg-[#2A2A2E] 
                ${selectedFace === img ? "ring-2 ring-green-400" : ""}`}
            >
              <img
                src={img}
                className="w-full h-full object-cover cursor-pointer"
                alt={`Image ${index + 1}`}
                onClick={() => handleImageClick(img)}
              />
            </div>
          ))
        ) : (
          <>{renderLoadingPlaceholders()}</>
        )}
      </div>
    </div>
  );
};

export default PhotoLibrary;
