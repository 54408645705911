const GenerateBackground = () => {
  return (
    <div className="p-4 bg-grayBG rounded-lg">
      <div className="">
        <h1>Enter Prompt</h1>
        <div className="h-[100px] overflow-hidden bg-[#2a2a2a] rounded-lg mt-4">
          <textarea
            type="text"
            className="w-full h-full bg-transparent p-2 outline-none"
          />
        </div>
      </div>
      <button className="w-full mt-4 py-2 bg-generate-gradient rounded-lg text-white">
        Generate
      </button>
    </div>
  );
};

export default GenerateBackground;
