export default function Loader() {
  return (
    <div className="cube">
      <div className="cube_item cube_x"></div>
      <div className="cube_item cube_y"></div>
      <div className="cube_item cube_y"></div>
      <div className="cube_item cube_x"></div>
    </div>
  );
}
