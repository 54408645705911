class UserProfile {
  constructor(
    id,
    email,
    firstName,
    lastName,
    profileImage,
    isActive,
    isVerified,
    phoneNumber,
  ) {
    this.id = id;
    this.email = email;
    this.first_name = firstName;
    this.last_name = lastName;
    this.profile_image = profileImage;
    this.is_active = isActive;
    this.is_verified = isVerified;
    this.phone_number = phoneNumber;
  }

  static fromJson(json) {
    return new UserProfile(
      json.id,
      json.email,
      json.first_name,
      json.last_name,
      json.profile_image,
      json.is_active,
      json.is_verified,
      json.phone_number,
    );
  }
  static toJson(user) {
    return JSON.stringify(user);
  }
}

class LoginResponse {
  constructor(expiry, token, user) {
    this.expiry = new Date(expiry);
    this.token = token;
    this.user = UserProfile.fromJson(user);
  }

  static fromJson(json) {
    if (!json.expiry || !json.token || !json.user) {
      throw new Error("Invalid login response format");
    }
    return new LoginResponse(json.expiry, json.token, json.user);
  }
}

export { LoginResponse, UserProfile };
