// ImageOutput.jsx
import React from "react";
import Loader from "./Loader";
import ImagePlayground from "./ImagePlayground";

const ImageOutput = ({
  isRunning,
  appliedFilter,
  selectedSize,
  faceImage,
  setStageRef,
  shadowProps,
  selectedModel,
  onMergeImage,
  blurAmount,
  blurBackground,
  showBackgroundRemoval,
  originalImage,
  maskingConfig,
}) => {
  const renderContent = () => {
    if (isRunning) {
      return (
        <div className="w-full mx-auto h-full">
          <div className="w-[550px] h-[600px] mx-auto bg-[#333537] rounded-lg flex flex-col items-center justify-center">
            <Loader />
          </div>
        </div>
      );
    }

    // Determine which image to show
    const imageToShow = showBackgroundRemoval ? appliedFilter : originalImage;

    if (faceImage?.newImageUrl || imageToShow) {
      return (
        <div className="flex-grow w-full mx-auto mt-8">
          <ImagePlayground
            containerWidth={selectedSize.width}
            containerHeight={selectedSize.height}
            backgroundSrc={faceImage}
            modelSrc={imageToShow}
            setStageRef={setStageRef}
            shadowProps={shadowProps}
            onMergeImage={onMergeImage}
            blurAmount={blurAmount}
            blurBackground={blurBackground}
            maskingConfig={maskingConfig}
          />
        </div>
      );
    }

    return (
      <div className="bg-[#333537] mx-auto p-4 w-[550px] h-[600px] flex flex-col items-center justify-center rounded-lg">
        <h1 className="text-sm text-gray-500">Nothing to see here yet!</h1>
      </div>
    );
  };

  return (
    <div className="w-full bg-black">
      <div className="flex flex-col items-center justify-center md:flex-row gap-4">
        <div className="w-full relative rounded-lg py-4">
          <div className="w-full mb-4">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default ImageOutput;
