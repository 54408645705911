import React, { useState } from "react";
import { X, ChevronRight } from "lucide-react";
import Crown from "../../../assets/Crown.svg";
import ShadowOptions from "./ShadowOptions";
import BlurBackground from "./BlurBG";
import Resize from "./Resize";
import GenerateBackground from "./GenerateBG";
import { colorPalette } from "../../../utils/utils";
import ColorBackgroundSelector from "./ColorBackgroundSelector";
import RemoveBG from "./RemoveBG";
import PhotoLibraryBG from "./PhotoLibraryBG";

const ImageSettings = ({
  images,
  currentImageIndex,
  modelImage,
  selectedFace,
  handleFaceImageSelect,
  handleFaceImageUpload,
  handleReset,
  renderLoadingPlaceholders,
  onShadowPropsChange,
  blurAmount,
  setBlurAmount,
  blurBackground,
  setBlurBackground,
  onResize,
  onRun,
  showBackgroundRemoval, // Add this
  onUpdateMask, // Add this
}) => {
  const [showLibrary, setShowLibrary] = useState(false);
  const [showShadowOptions, setShowShadowOptions] = useState(false);
  const [showGenerateBackground, setShowGenerateBackground] = useState(false);
  const [showBlurBackground, setShowBlurBackground] = useState(false);
  const [showRemoveBackground, setShowRemoveBackground] = useState(false);
  const [showResize, setShowResize] = useState(false);
  const [libraryTab, setLibraryTab] = useState("photos");

  const settingsItems = [
    {
      title: "Background Image",
      isOpen: showLibrary,
      setIsOpen: setShowLibrary,
      content: (
        <div>
          <div className="flex bg-black p-1 items-center justify-around rounded-2xl mb-2">
            <button
              className={`px-4 py-1 ${libraryTab === "photos" ? "bg-generate-gradient" : "hover:bg-grayBG"} transition-all duration-200 rounded-2xl`}
              onClick={() => setLibraryTab("photos")}
            >
              Photos
            </button>
            <button
              className={`px-4 py-1 ${libraryTab === "colors" ? "bg-generate-gradient" : "hover:bg-grayBG"} transition-all duration-200 rounded-2xl`}
              onClick={() => setLibraryTab("colors")}
            >
              Colors
            </button>
          </div>

          {libraryTab === "photos" ? (
            <PhotoLibraryBG
              images={images}
              currentImageIndex={currentImageIndex}
              selectedFace={selectedFace}
              handleFaceImageSelect={handleFaceImageSelect}
              renderLoadingPlaceholders={renderLoadingPlaceholders}
              handleReset={handleReset}
              handleImageUpload={handleFaceImageUpload}
            />
          ) : (
            <ColorBackgroundSelector
              handleFaceImageSelect={handleFaceImageSelect}
            />
          )}
        </div>
      ),
    },
    {
      title: "Background Remover",
      isOpen: showRemoveBackground,
      setIsOpen: setShowRemoveBackground,
      content: (
        <RemoveBG
          onClose={() => setShowRemoveBackground(false)}
          onRun={onRun}
          modelImage={modelImage}
          showBackgroundRemoval={showBackgroundRemoval}
          onUpdateMask={onUpdateMask} // Add this new prop
        />
      ),
    },
    {
      title: "Add Shadow",
      isOpen: showShadowOptions,
      setIsOpen: setShowShadowOptions,
      content: <ShadowOptions onShadowPropsChange={onShadowPropsChange} />,
    },
    {
      title: "Generate Background",
      isOpen: showGenerateBackground,
      setIsOpen: setShowGenerateBackground,
      content: <GenerateBackground />,
      isPremium: true,
    },
    {
      title: "Blur Background",
      isOpen: showBlurBackground,
      setIsOpen: setShowBlurBackground,
      content: (
        <BlurBackground
          blurAmount={blurAmount}
          setBlurAmount={setBlurAmount}
          blurBackground={blurBackground}
          setBlurBackground={setBlurBackground}
          onClose={() => setShowBlurBackground(false)}
        />
      ),
    },
    {
      title: "Resize",
      isOpen: showResize,
      setIsOpen: setShowResize,
      content: <Resize onResize={onResize} />,
    },
  ];

  return (
    <ul className="flex flex-col gap-y-4 text-white">
      {settingsItems.map((item, index) => (
        <li
          key={index}
          className={`flex flex-col ${item.isOpen ? "bg-grayBG p-4 rounded-xl" : ""} justify-between cursor-pointer`}
          onClick={() => item.setIsOpen(true)}
        >
          <span className="flex items-center mb-2 w-full justify-between text-white">
            <div className="flex items-center">
              <a>{item.title}</a>
              {item.isPremium && (
                <img
                  src={Crown}
                  className="ml-1 w-4 h-4"
                  alt="Premium feature"
                />
              )}
            </div>
            {item.isOpen ? (
              <X
                className="hover:bg-grayBG2 rounded-full p-1"
                onClick={(e) => {
                  e.stopPropagation();
                  item.setIsOpen(false);
                }}
              />
            ) : (
              <ChevronRight />
            )}
          </span>
          {item.isOpen && item.content}
        </li>
      ))}
    </ul>
  );
};

export default ImageSettings;
