import React, { useState } from "react";
import { CheckCircle } from "lucide-react";

const ToggleButton: React.FC<{
  selected: string;
  setSelected: (value: "monthly" | "yearly") => void;
}> = ({ selected, setSelected }) => {
  return (
    <div className="flex bg-gray-200 dark:bg-gray-300 rounded-md p-1 w-72 shadow-md">
      <button
        className={`flex-1 text-sm font-semibold px-4 py-2 rounded-md transition-all duration-300 ${
          selected === "monthly"
            ? "bg-white shadow text-black"
            : "text-gray-500 dark:text-gray-400"
        }`}
        onClick={() => setSelected("monthly")}
      >
        Pay Monthly
      </button>
      <button
        className={`flex-1 text-sm font-semibold px-4 py-2 rounded-md transition-all duration-300 ${
          selected === "yearly"
            ? "bg-white shadow text-black"
            : "text-gray-500 dark:text-gray-400"
        }`}
        onClick={() => setSelected("yearly")}
      >
        Pay Yearly
      </button>
    </div>
  );
};

interface DescriptionCardProps {
  imageSrc: string;
  title: string;
  description: string;
  credits: string;
}
const DescriptionCard: React.FC<DescriptionCardProps> = ({
  imageSrc,
  title,
  description,
  credits,
}) => {
  return (
    <div
      className="w-[310px] bg-white dark:bg-gray-900 rounded-xl shadow-lg p-4 flex flex-col items-center text-center border border-gray-200 dark:border-gray-700
      transform transition duration-300 ease-out hover:scale-105 hover:shadow-2xl opacity-80 animate-fadeIn"
    >
      <img
        src={imageSrc}
        alt={title}
        className="w-40 h-40 object-contain transition-transform duration-300 hover:scale-110"
      />
      <h2 className="text-xl font-semibold text-gray-900 dark:text-white mt-4">
        {title}
      </h2>
      <p className="text-gray-600 dark:text-gray-300 text-sm mt-2">
        {description}
      </p>
      <span className="text-blue-800 dark:text-blue-700 font-medium mt-3">
        {credits}
      </span>
    </div>
  );
};

interface PricingCardProps {
  title: string;
  subtitle: string;
  price: number;
  featuresTitle: string;
  features: string[];
  buttonText: string;
  onClick: () => void;
}

interface PricingCardProps {
  title: string;
  subtitle: string;
  price: number;
  yearlyPrice: number;
  selectedPlan: "monthly" | "yearly";
  featuresTitle: string;
  features: string[];
  buttonText: string;
  onClick: () => void;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  subtitle,
  price,
  yearlyPrice,
  selectedPlan,
  features,
  featuresTitle,
  buttonText,
  onClick,
}) => {
  return (
    <div className="w-full max-w-sm border border-gray-700 p-6 rounded-xl shadow-md bg-gray-900 text-white mx-auto transition transform hover:scale-105 duration-300">
      <div className="flex flex-col items-start mb-4">
        <h2 className="text-2xl font-bold mb-2 text-center">{title}</h2>
        <p className="text-sm mb-4 text-start">{subtitle}</p>
      </div>

      <div className="flex flex-col items-center justify-center mb-4">
        <p className="text-3xl font-semibold mb-2">
          ₹{selectedPlan === "monthly" ? price : yearlyPrice}
          <span className="text-sm font-light">
            /{selectedPlan === "monthly" ? "mo" : "yr"}
          </span>
        </p>
        {/* {selectedPlan === "yearly" && (
          <p className="text-green-400 text-xs">Save 20%</p>
        )} */}
      </div>

      <button
        onClick={onClick}
        className="bg-blue-600 font-bold text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 w-full text-center"
      >
        {buttonText}
      </button>

      <div className="w-full h-px bg-gray-600 my-8"></div>

      <h3 className="text-sm font-semibold text-gray-400 mb-4 text-start">
        {featuresTitle}
      </h3>
      <ul className="mb-6 text-gray-400">
        {features.map((feature, index) => (
          <li key={index} className="mb-2 flex items-center gap-2">
            <CheckCircle className="text-blue-500" /> {feature || "Features"}
          </li>
        ))}
      </ul>
    </div>
  );
};
const Pricing: React.FC = () => {
  const handleSignUp = () => {
    console.log("Sign Up clicked");
  };

  const handleGetStarted = () => {
    console.log("Get Started clicked");
  };
  const [selectedPlan, setSelectedPlan] = useState<"monthly" | "yearly">(
    "monthly",
  );
  return (
    <div className="p-6 bg-gray-800 min-h-screen flex items-center justify-center flex-col">
      <div className="max-w-xl mx-auto text-center">
        <h1 className="text-4xl font-bold mb-6 text-white leading-2">
          Discover the perfect plan tailored to you.
        </h1>
        <p className="text-lg text-gray-400 mb-12">
          One-stop solution for all your fashion cataloging needs.
        </p>
      </div>
      <div className="py-8 mb-12">
        <ToggleButton selected={selectedPlan} setSelected={setSelectedPlan} />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <PricingCard
          title="Free"
          subtitle="Simplify fashion cataloging with our free AI-powered features."
          price={0}
          yearlyPrice={0}
          selectedPlan={selectedPlan}
          featuresTitle="Free Includes"
          features={["15 Credits", "Face Swap", "Background Swap"]}
          buttonText="Sign Up for Free"
          onClick={handleSignUp}
        />
        <PricingCard
          title="Starter"
          subtitle="Ideal for store owners, freelancers and smaller stores."
          price={1999}
          yearlyPrice={19999}
          selectedPlan={selectedPlan}
          featuresTitle="Everything in Free, plus"
          features={["200 Credits", "Advanced Tools", "Priority Support"]}
          buttonText="Get Started"
          onClick={handleGetStarted}
        />
        <PricingCard
          title="Growth"
          subtitle="Perfect for fast-growing stores and ecommerce marketing."
          price={3999}
          yearlyPrice={39999}
          selectedPlan={selectedPlan}
          featuresTitle="Everything in Starter, plus"
          features={["500 Credits", "Premium AI Features", "Dedicated Support"]}
          buttonText="Get Started"
          onClick={handleGetStarted}
        />
        <PricingCard
          title="Pro"
          subtitle="For busy stores. Have our expert team generate images for you."
          price={5999}
          yearlyPrice={59999}
          selectedPlan={selectedPlan}
          featuresTitle="Everything in Growth, plus"
          features={[
            "1000 Credits",
            "Team Collaboration",
            "Personalized Assistance",
          ]}
          buttonText="Get Started"
          onClick={handleGetStarted}
        />
        <PricingCard
          title="Enterprise"
          subtitle="Customize scalable workflows to fit your business perfectly."
          price={7999}
          yearlyPrice={79999}
          selectedPlan={selectedPlan}
          featuresTitle="Everything in Pro, plus"
          features={[
            "Unlimited Credits",
            "Enterprise AI Solutions",
            "Dedicated Account Manager",
          ]}
          buttonText="Contact Us"
          onClick={handleGetStarted}
        />
      </div>
      <div className="max-w-6xl mx-auto text-center mt-28">
        <h1 className="text-5xl font-bold mb-6 text-white">
          Features & Credit Charges
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 py-12 md:py-16 lg:py-20">
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Face Swap"
          description="Change the face of your models. No need to work with multiple models."
          credits="1 Credit - 1 Image"
        />
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Background Swap"
          description="Transform backgrounds effortlessly—no need for multiple setups or locations!"
          credits="1 Credit - 1 Image"
        />
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Model Swap"
          description="Upgrade to conventionally beautiful international models seamlessly."
          credits="2 Credits - 1 Image"
        />
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Posture Swap"
          description="Why stuck with only one pose when you can try at all angles."
          credits="2 Credits - 1 Image"
        />
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Mannequin to Model"
          description="Replace the mannequin with a synthetic human of any ethnicity."
          credits="2 Credits - 1 Image"
        />
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Cloth to Mannequin"
          description="Visualize your apparel on mannequins for a realistic, polished display."
          credits="2 Credits - 1 Image"
        />
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Image to Video"
          description="Convert static images into dynamic, engaging videos effortlessly."
          credits="4 Credits - 1 Video"
        />
        <DescriptionCard
          imageSrc="https://placehold.co/150"
          title="Description Generator"
          description="Generate tailored, engaging product descriptions in seconds."
          credits="1 Credit - 1 Generation"
        />
      </div>
    </div>
  );
};

export default Pricing;
