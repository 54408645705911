import React, { useState, useRef } from "react";
import { UploadCloud, UploadIcon } from "lucide-react";

const FileUploader = ({ handleImageUpload, title = "Upload" }) => {
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      console.error("No files selected");
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => setPreview(e.target.result);
    reader.readAsDataURL(file);
    handleImageUpload(e);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="grid bg-grayBG grid-cols-3 items-center justify-center p-2 mx-auto  gap-3 rounded-lg">
      {/* Upload Card */}
      <div
        className="bg-transparent border-[1px] border-[#4f4f4f] border-dashed text-white cursor-pointer flex flex-col gap-2 justify-center items-center h-[80px] w-[78px] rounded-lg"
        onClick={handleClick}
      >
        <div>
          <UploadIcon />
        </div>
        <div className="flex flex-col items-center">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
          <span className="text-sm text-center">{title}</span>
        </div>
      </div>

      {/* for now : Placeholders for Recent Images Cards */}
      <div className="bg-grayBG3 h-[80px] w-[78px] rounded-lg"></div>
      <div className="bg-grayBG3 h-[80px] w-[78px] rounded-lg"></div>
    </div>
  );
};

export default FileUploader;
