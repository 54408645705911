import React, { useState } from "react";
import { Pencil, Sparkles, Download, Check, X } from "lucide-react";
import { DownloadButton } from "../Utils/DownloadButton.jsx";
import { generateUniqueFilename } from "../../utils/utils.jsx";

const RightDock = ({
  onRun,
  selectedFace,
  selectedModel,
  isRunning,
  outputImage,
  onMergeImage,
  canMerge,
  feature = "face-swap",
}) => {
  const [skuId, setSkuId] = useState("1234");
  const [isEditing, setIsEditing] = useState(false);
  const [tempSkuId, setTempSkuId] = useState(skuId);

  const handleEditClick = () => {
    setIsEditing(true);
    setTempSkuId(skuId);
  };

  const handleSave = () => {
    setSkuId(tempSkuId);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setTempSkuId(skuId);
    setIsEditing(false);
  };

  const renderDownloadButton = () => {
    if (feature === "bg-swap") {
      return (
        <button
          onClick={() => onMergeImage()}
          disabled={!canMerge}
          className="text-black bg-customYellow w-full py-3 px-8 rounded-md cursor-pointer hover:opacity-70 transition-opacity flex gap-2 items-center justify-center"
        >
          <Download size={16} />
          <span className="text-sm">Download</span>
        </button>
      );
    }
    if (feature === "description-gen") {
      return (
        <button className="text-black bg-customYellow w-full py-3 px-8 rounded-md cursor-pointer hover:opacity-70 transition-opacity flex gap-2 items-center justify-center">
          <Download size={16} />
          <span className="text-sm">Download</span>
        </button>
      );
    }

    return <DownloadButton imageUrl={outputImage} />;
  };

  return (
    <div className="lg:w-[700px]">
      <div className="mt-4 px-8 pb-4 flex flex-col justify-center gap-4 w-full">
        <div>
          <button
            onClick={onRun}
            disabled={
              feature === "description-gen"
                ? !selectedFace.blobImageUrl
                : !selectedFace.blobImage ||
                  !selectedModel.blobImage ||
                  isRunning
            }
            className={` ${feature === "bg-swap" ? "hidden" : "block"} text-white w-full bg-[#333537] py-3 px-8 rounded-md cursor-pointer hover:bg-[#474a4d] transition-colors flex gap-2 items-center justify-center`}
          >
            <span className="text-sm flex items-center gap-2">
              <Sparkles size={18} />
              {isRunning ? "Generating..." : "Generate"}
            </span>
          </button>
        </div>
        <div>{renderDownloadButton()}</div>
      </div>
      <div className="flex text-white items-center justify-around px-8 py-4 border-b border-t border-[#414141]">
        {isEditing ? (
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={tempSkuId}
              onChange={(e) => setTempSkuId(e.target.value)}
              className="bg-[#333537] text-white px-2 py-1 rounded-md text-base w-32"
              autoFocus
            />
            <button
              onClick={handleSave}
              className="hover:text-green-500 transition-colors"
            >
              <Check size={16} />
            </button>
            <button
              onClick={handleCancel}
              className="hover:text-red-500 transition-colors"
            >
              <X size={16} />
            </button>
          </div>
        ) : (
          <>
            <h1 className="text-base">SKU ID: {skuId}</h1>
            <button
              onClick={handleEditClick}
              className="hover:text-gray-300 transition-colors"
            >
              <Pencil size={16} />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default RightDock;
