import React from "react";

const BlurBackground = ({
  blurAmount,
  setBlurAmount,
  blurBackground,
  setBlurBackground,
  onClose,
}) => {
  const handleBlurChange = (event) => {
    setBlurAmount(event.target.value);
  };

  const handleBlurBackgroundToggle = () => {
    setBlurBackground((prev) => !prev);
  };

  const handleReset = () => {
    setBlurAmount(0);
    setBlurBackground(false);
  };

  const handleDone = (e) => {
    e.stopPropagation(); // Add this to prevent event bubbling
    onClose && onClose(e);
  };

  return (
    <div className="mt-4 bg-grayBG rounded-lg">
      <div className="mb-4">
        <label
          htmlFor="blur-background"
          className="block text-sm font-light mb-2"
        >
          Blur background
        </label>
        <div
          className={`w-14 h-8 bg-gray-400 rounded-full p-1 cursor-pointer ${
            blurBackground ? "justify-end" : "justify-start"
          }`}
          onClick={handleBlurBackgroundToggle}
        >
          <div
            className={`w-6 h-6 bg-black rounded-full transition-transform duration-300 ${
              blurBackground ? "transform translate-x-6" : ""
            }`}
          ></div>
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="blur-amount" className="block font-medium mb-2">
          Blur amount
        </label>
        <input
          id="blur-amount"
          type="range"
          min="0"
          max="100"
          value={blurAmount}
          onChange={handleBlurChange}
          className="w-full appearance-none bg-transparent 
          [&::-webkit-slider-thumb]:appearance-none 
          [&::-webkit-slider-thumb]:h-4 
          [&::-webkit-slider-thumb]:w-4 
          [&::-webkit-slider-thumb]:bg-purple-500 
          [&::-webkit-slider-thumb]:rounded-full 
          [&::-webkit-slider-thumb]:relative 
          [&::-webkit-slider-thumb]:-top-1.5 
          [&::-webkit-slider-runnable-track]:bg-white 
          [&::-webkit-slider-runnable-track]:h-1 
          [&::-webkit-slider-runnable-track]:rounded-full"
        />
        <p className="text-right text-sm">{blurAmount}%</p>
      </div>

      <div className="mt-4 flex text-white items-center gap-4 justify-between">
        <button
          className="w-full py-4 rounded-lg bg-grayBG2"
          onClick={handleReset}
        >
          Reset
        </button>
        <button
          className={`w-full py-4 rounded-lg bg-generate-gradient`}
          onClick={handleDone}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default BlurBackground;
