import React, { useState, useEffect } from "react";
import { Image as ImageIcon, X } from "lucide-react";
import { useSelector } from "react-redux";

import RightDock from "./RightDock.jsx";
import Loader from "./BackgroundSwap/Loader.jsx";
import ImageSection from "./ImageSection.jsx";
import FullscreenImageModal from "./FullScreenImageModal.jsx";

// ImageProcessor Component
const ImageProcessor = ({
  targetTitle,
  images,
  modelImages,
  sourceTitle,
  onFaceSelect,
  onModelSelect,
  onFaceUpload,
  onModelUpload,
  onRun,
  selectedFace,
  selectedModel,
  outputImage,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
}) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [modelImage, setModelImage] = useState({});

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`${isSidebarOpen ? "responsive-img-p" : ""} mt-16 bg-[#222226] transition-all duration-200 rounded-xl`}
    >
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col relative px-2 ml-6 my-2 overflow-auto lg:w-[700px] h-screen">
          {/* Source Image Section */}
          {faceImage?.newImageUrl ? (
            <div className="ml-6 relative">
              <h1 className="text-white my-4">{sourceTitle}</h1>
              <div
                className="w-[150px] h-[150px] relative bg-grayBG2 rounded-lg mb-4"
                onClick={() => openFullscreenImage(faceImage.newImageUrl)}
              >
                <img
                  src={faceImage.newImageUrl}
                  alt="preview"
                  className="w-full h-full object-cover rounded-lg cursor-pointer"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent fullscreen modal from opening
                    setFaceImage({});
                  }}
                  className="absolute top-0 right-0 rounded-full p-1"
                >
                  <X color="red" size={16} />
                </button>
              </div>
            </div>
          ) : (
            <ImageSection
              title={sourceTitle}
              images={images}
              selectedImage={faceImage}
              onImageSelect={handleFaceImageSelect}
              onImageUpload={handleFaceImageUpload}
              onReset={() => setFaceImage({})}
              renderLoadingPlaceholders={renderLoadingPlaceholders}
              type="source"
            />
          )}
          <div className="border-b border-[#484848] pb-4"></div>
          {/* Target Image Section */}
          {modelImage?.newImageUrl ? (
            <div className="ml-6 relative">
              <h1 className="text-white my-4">{targetTitle}</h1>
              <div
                className="w-[150px] h-[150px] relative bg-grayBG2 rounded-lg mb-4"
                onClick={() => openFullscreenImage(modelImage.newImageUrl)}
              >
                <img
                  src={modelImage.newImageUrl}
                  alt="preview"
                  className="w-full h-full object-cover rounded-lg cursor-pointer"
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent fullscreen modal from opening
                    setModelImage({});
                  }}
                  className="absolute top-0 right-0  rounded-full p-1"
                >
                  <X color="red" size={16} />
                </button>
              </div>
            </div>
          ) : (
            <ImageSection
              title={targetTitle}
              images={modelImages}
              selectedImage={modelImage}
              onImageSelect={handleModelImageSelect}
              onImageUpload={handleModelImageUpload}
              onReset={() => setModelImage({})}
              renderLoadingPlaceholders={renderLoadingPlaceholders}
              type="target"
            />
          )}
        </div>

        <div className="w-full min-h-screen bg-black">
          <div className="flex flex-col items-center justify-center md:flex-row gap-4">
            <div className="w-full relative rounded-lg py-4">
              <div className="aspect-square h-[600px] mx-auto w-[500px] mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <Loader />
                    </div>
                  </div>
                ) : outputImage ? (
                  <img
                    src={outputImage}
                    alt="filtered"
                    className="w-full h-full object-contain rounded-lg cursor-pointer"
                    onClick={() => openFullscreenImage(outputImage)}
                  />
                ) : (
                  <div className="bg-[#333537] p-4 h-[600px] flex flex-col items-center justify-center rounded-lg">
                    {/* <ImageIcon size={200} className="text-gray-300" /> */}
                    <h1 className="text-sm text-gray-500">
                      Nothing to see here yet!
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <RightDock
          onRun={onRun}
          selectedFace={selectedFace}
          selectedModel={selectedModel}
          isRunning={isRunning}
          outputImage={outputImage}
          feature={"face-swap"}
        />

        {/* Fullscreen Modal */}
        <FullscreenImageModal
          imageUrl={fullscreenImage}
          onClose={closeFullscreenImage}
        />
      </div>
    </div>
  );
};

export default ImageProcessor;
