import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "../Utils/ThemeContext";
import { UserContext } from "../Auth/UserContext";
import { getProfile, logOut } from "../../api/authApi";
import {
  toggleSidebar,
  setSidebarState,
  setCatalogType,
} from "../../redux/studioSlice";
import {
  User,
  Menu,
  X,
  LogOut,
  Home,
  Image,
  DollarSign,
  Edit,
  Phone,
  FileText,
  Shield,
  Layers,
  Shirt,
  Palette,
  AlignCenter,
  Crown,
  ChevronDown,
  CircleChevronDown,
  ChevronUp,
  CircleChevronUp,
} from "lucide-react";
import "./Navbar.css";
import logo from "../../assets/images/logo.svg";
import { AIFeatureViewNames } from "../../config/constants";
import { useLocation } from "react-router-dom";
import StudioNavbar from "./StudioNavbar";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    profile_image: "",
    email: "",
    photo: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [isCatalogDropdownOpen, setIsCatalogDropdownOpen] = useState(false);

  const toggleCatalogDropdown = () => {
    setIsCatalogDropdownOpen(!isCatalogDropdownOpen);
  };

  const handleCatalogTypeChange = (type) => {
    dispatch(setCatalogType(type));
    setIsCatalogDropdownOpen(false);
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const { mode } = useContext(ThemeContext);

  // Get sidebar state from Redux
  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);
  const location = useLocation();

  // Updated condition to include image-to-video path
  const shouldHideSidebar =
    location.pathname === "/dashboard/home" ||
    location.pathname === "/dashboard/profile" ||
    location.pathname === "/dashboard/image-to-video" ||
    location.pathname === "/dashboard/pricing";

  // Updated condition for hiding the sidebar toggle button
  const shouldHideSidebarToggle =
    location.pathname === "/dashboard/home" ||
    location.pathname === "/dashboard/profile" ||
    location.pathname === "/dashboard/image-to-video" ||
    location.pathname === "/dashboard/pricing";

  const shouldHideNavbar =
    location.pathname === "/dashboard/home" ||
    location.pathname === "/dashboard/profile";

  const toggleDropdown = () => setDropDownOpen(!dropDownOpen);
  const toggleSidebarHandler = () => dispatch(toggleSidebar());

  const catalogType = useSelector((state) => state.studio.catalogType);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userProfile = await getProfile();
        setProfileData(userProfile.data);
      } catch (error) {
        console.error("Failed to fetch profile: ", error);
      }
    };
    fetchProfile();
  }, [isSidebarOpen, dispatch]);

  useEffect(() => {
    // Set catalog type based on current route when component mounts
    if (location.pathname.includes("/dashboard/bg-swap")) {
      const savedType = localStorage.getItem("catalogType");
      if (!savedType) {
        dispatch(setCatalogType("product"));
      }
    }
    // Add other route checks as needed
  }, []);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName?.charAt(0) || ""}${lastName?.charAt(0) || ""}`.toUpperCase();
  };

  const getColorFromName = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - c.length) + c;
  };

  const menuItems = [
    { label: "Home", icon: Home, link: "/dashboard/home" },
    { label: "Profile", icon: User, link: "/dashboard/profile" },
    { label: "Gallery", icon: Image, link: "/dashboard/gallery" },
    { label: "Pricing", icon: DollarSign, link: "#" },
    { label: "Contact Us", icon: Phone, link: "#" },
    { label: "Terms and Conditions", icon: FileText, link: "#" },
    { label: "Privacy Policy", icon: Shield, link: "#" },
    { label: "Sign out", icon: LogOut, onClick: handleLogout },
  ];

  const apparelSidebarItems = [
    {
      label: AIFeatureViewNames.FACE_SWAP,
      icon: Layers,
      link: "/dashboard/faceswap",
    },
    {
      label: AIFeatureViewNames.BG_EDIT,
      icon: Palette,
      link: "/dashboard/bg-swap",
    },
    {
      label: AIFeatureViewNames.APPAREL_SWAP,
      icon: Shirt,
      link: "/dashboard/apparel-swap",
    },
    {
      label: AIFeatureViewNames.MODEL_SWAP,
      icon: Palette,
      link: "/dashboard/model-swap",
    },
    {
      label: AIFeatureViewNames.DESC_GEN,
      icon: Palette,
      link: "/dashboard/description-generator",
    },
    {
      label: AIFeatureViewNames.BG_GEN,
      icon: Palette,
      link: "/dashboard/bg-generator",
    },
    {
      label: AIFeatureViewNames.MODEL_GEN,
      icon: Palette,
      link: "/dashboard/model-generator",
    },

    // {
    //   label: AIFeatureViewNames.CATALOGUER,
    //   icon: Edit,
    //   link: "/dashboard/generate-catalogue",
    // },
    // {
    //   label: AIFeatureViewNames.BG_GEN,
    //   icon: Edit,
    //   link: "/dashboard/bg-generator",
    // },
  ];

  const productSidebarItems = [
    {
      label: "Background Fill",
      icon: Image,
      link: "/dashboard/bg-fill",
      disabled: false,
    },

    {
      label: "Product Try-On",
      icon: Layers,
      link: "/dashboard/product-try-on",
      disabled: false,
    },
    {
      label: "PropAI Fill",
      icon: Shirt,
      link: "/dashboard/prop-fill",
      disabled: false,
    },
    {
      label: "Background Editor",
      icon: Image,
      link: "/dashboard/bg-swap",
      disabled: false,
    },
    {
      label: "Background Generator",
      icon: Image,
      link: "/dashboard/bg-generator",
      disabled: false,
    },
    {
      label: "Object Remover",
      icon: Image,
      link: "/dashboard/object-remover",
      disabled: false,
    },
    {
      label: "Color Variant Generation",
      icon: Image,
      link: "/dashboard/lighting-adjustments",
      disabled: true,
    },
    {
      label: "3D Rendering",
      icon: Image,
      link: "/dashboard/Rendering",
      disabled: true,
    },
    {
      label: "Lighting Adjustments",
      icon: Image,
      link: "/dashboard/lighting-adjustments",
      disabled: true,
    },
    {
      label: "Image Engineering",
      icon: Image,
      link: "/dashboard/image-engineering",
      disabled: true,
    },
    {
      label: "Animated Product GIFs",
      icon: Image,
      link: "/dashboard/animated-gif",
      disabled: true,
    },
  ];

  const activeSidebarItems = catalogType.apparelCatalog
    ? apparelSidebarItems
    : catalogType.productCatalog
      ? productSidebarItems
      : [];

  return (
    <div>
      {!shouldHideSidebar && (
        <div
          id="sidebar"
          className={` hidden fixed top-0 left-0 border-r border-[#424242] h-full mt-12 w-56 bg-[#222226] transform transition-transform duration-300 ease-in-out z-50 
      ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}`}
        >
          <div className="py-6">
            {activeSidebarItems.map((item, index) => (
              <NavLink
                key={index}
                to={item.disabled ? "#" : item.link}
                className={({ isActive }) =>
                  `block py-4 transition-all duration-300 ease-in-out group ${
                    isActive && !item.disabled
                      ? "text-[#fff] font-medium bg-generate-gradient"
                      : "text-gray-400 border-transparent"
                  } ${
                    item.disabled
                      ? "cursor-not-allowed opacity-50 hover:text-gray-400"
                      : "hover:text-[#a7a7a8]"
                  }`
                }
                onClick={(e) => {
                  if (item.disabled) {
                    e.preventDefault();
                    return;
                  }
                  setActiveLink(index);
                }}
              >
                <div className="flex items-center pl-4">
                  <span
                    className={`text-base font-medium group-hover:tracking-wider transition-all duration-200 block text-left ${
                      item.disabled ? "text-gray-500" : ""
                    }`}
                  >
                    {item.label}
                  </span>
                  {item.disabled && (
                    <span className="ml-2 text-xs bg-gray-600 text-gray-300 px-2 py-1 rounded">
                      Coming Soon
                    </span>
                  )}
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      )}

      {/* Navbar1 */}
      <nav
        className={`navbar-1 ${shouldHideNavbar ? "dashboard-home" : ""} shadow-md 
           bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white
        }`}
      >
        <div className="max-w-8xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0 flex items-center gap-12">
              <Link
                to="/dashboard/home"
                className="text-xl mt-1 font-bold text-white"
              >
                <img className="h-8 w-auto" src={logo} alt="Logo" />
              </Link>
              <div className=" nav-container">
                <Link
                  to="/dashboard/home"
                  className="text-white hover:text-gray-200 transition duration-300"
                >
                  <h1>Home</h1>
                </Link>
                <div className="relative">
                  <div
                    className="flex gap-2 items-center cursor-pointer text-white transition-colors duration-300 px-3 py-2 rounded-md"
                    onClick={toggleCatalogDropdown}
                  >
                    {catalogType.apparelCatalog
                      ? "Apparel Cataloguing"
                      : "Product Cataloguing"}
                    {isCatalogDropdownOpen ? (
                      <CircleChevronUp
                        size={16}
                        className="transition-transform"
                      />
                    ) : (
                      <CircleChevronDown
                        size={16}
                        className="transition-transform"
                      />
                    )}
                  </div>
                  {isCatalogDropdownOpen && (
                    <div className="absolute w-full top-8 p-2  left-0 mt-2 bg-[#2C2C2C] shadow-lg rounded-md z-50 border border-gray-700 overflow-hidden">
                      <Link
                        to="/dashboard/faceswap"
                        className={`block p-3 hover:bg-gray-700 cursor-pointer mb-2 rounded-lg transition-colors duration-300 ${
                          catalogType.apparelCatalog
                            ? " bg-grayBG text-white"
                            : "text-gray-300"
                        }`}
                        onClick={() => handleCatalogTypeChange("apparel")}
                      >
                        Apparel Cataloguing
                      </Link>
                      <Link
                        to="/dashboard/bg-fill"
                        className={`block p-3 hover:bg-gray-700 rounded-lg cursor-pointer transition-colors duration-300 ${
                          catalogType.productCatalog
                            ? "bg-grayBG text-white"
                            : "text-gray-300"
                        }`}
                        onClick={() => handleCatalogTypeChange("product")}
                      >
                        Product Cataloguing
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="hidden sm:flex sm:items-center sm:ml-6 space-x-4">
              {menuItems.slice(0, -3).map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className="text-gray-300 hover:bg-blue-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out flex items-center"
                >
                  <item.icon className="inline-block w-5 h-5 mr-2" />
                  {item.label}
                </Link>
              ))}
            </div>

            <div className="flex items-center">
              <div className=" menu-container flex items-center h-full gap-12 mr-4">
                <button className="px-2 py-1 border flex gap-2 items-center rounded-lg hover:bg-gray-900">
                  <Crown className="w-5 h-5" fill="white" />
                  <p className="text-sm">Upgrade</p>
                </button>
                <h1 className="text-base ">Credits: 10</h1>
              </div>
              <div className="ml-3 relative hide-on-larger-than-sm">
                <button
                  className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition duration-300 ease-in-out"
                  onClick={toggleDropdown}
                  aria-haspopup="true"
                >
                  {profileData?.profile_image ? (
                    <img
                      className="h-8 w-8 rounded-full object-cover"
                      src={profileData.profile_image}
                      alt="User avatar"
                    />
                  ) : (
                    <div
                      className="h-8 w-8 rounded-full flex items-center justify-center text-white text-sm font-medium"
                      style={{
                        backgroundColor: getColorFromName(
                          `${profileData.first_name} ${profileData.last_name}`,
                        ),
                      }}
                    >
                      {getInitials(
                        profileData.first_name,
                        profileData.last_name,
                      )}
                    </div>
                  )}
                </button>
                {dropDownOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-80 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 overflow-hidden">
                    <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
                      <p className="text-lg font-semibold">
                        {profileData.first_name || "User"}
                      </p>
                      <p className="text-sm opacity-80">
                        {profileData.email || ""}
                      </p>
                    </div>
                    <div className="py-2">
                      {menuItems.map((item, index) => (
                        <Link
                          key={index}
                          to={item.link}
                          onClick={() => {
                            setDropDownOpen(false);
                            if (item.onClick) item.onClick();
                          }}
                          className="flex items-center px-6 py-3 text-sm text-gray-800 hover:bg-gray-50 transition duration-300 ease-in-out group"
                        >
                          <item.icon className="w-5 h-5 mr-3 text-gray-400 group-hover:text-blue-500 transition-colors duration-300" />
                          <span
                            className="group-hover:text-blue-600 transition-colors duration-300"
                            style={{ display: "block" }}
                          >
                            {item.label}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <button
                onClick={() => setIsOpen(!isOpen)}
                className="ml-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white sm:hidden transition duration-300 ease-in-out"
                aria-expanded={isOpen}
              >
                {isOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <div className="px-3 py-2 text-gray-300 font-medium">
                Hello, {profileData.first_name || "User"}
              </div>
              {menuItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  onClick={() => {
                    setIsOpen(false);
                    if (item.onClick) item.onClick();
                  }}
                  className="flex items-center text-gray-300 hover:bg-blue-700 hover:text-white px-3 py-2 rounded-md text-base font-medium transition duration-300 ease-in-out"
                >
                  <item.icon className="w-5 h-5 mr-2" />
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </nav>

      {/* StudioNavbar */}
      <StudioNavbar
        logo={logo}
        profileData={profileData}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default Navbar;
