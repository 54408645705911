const ToggleButton = ({ options, mode, setMode }) => {
  return (
    <div className="flex bg-gray-950 rounded-xl p-1 max-w-sm shadow-md justify-between mx-auto my-4">
      {options.map((option) => (
        <button
          key={option.value}
          className={`flex justify-center items-center text-sm px-2 w-[50%] py-1 rounded-xl transition-all duration-300 ${
            mode === option.value
              ? "bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white"
              : "text-gray-500 dark:text-gray-400"
          }`}
          onClick={() => setMode(option.value)}
        >
          {/* Render icon only if it exists */}
          {option.icon && <span className="mr-1">{option.icon}</span>}
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleButton;
