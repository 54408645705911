import React from "react";
import { X } from "lucide-react";

const FullscreenImageModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50 p-4"
    >
      <div className="relative w-full h-full max-w-4xl max-h-4xl">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 z-60 bg-white/20 hover:bg-white/40 rounded-full p-2"
        >
          <X color="white" size={24} />
        </button>
        <img
          src={imageUrl}
          alt="fullscreen"
          className="w-full h-full object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

export default FullscreenImageModal;
