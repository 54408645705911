import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import bannerBG from "../../assets/images/bannerBG.png";
import bannerAI from "../../assets/images/banner_Ai.png";
import apparelC from "../../assets/images/apparelC.png";
import ImageE from "../../assets/images/ImageE.png";
import ProductC from "../../assets/images/ProductC.png";
import imageTovideo from "../../assets/imagetoVideo.gif";
import { useDispatch } from "react-redux";
import { setCatalogType } from "../../redux/studioSlice";

const Banner = () => {
  const dispatch = useDispatch();

  const handleCatalogClick = (type) => {
    dispatch(setCatalogType(type));
  };

  return (
    <div className="h-[calc(100vh-64px)] responsive-ml ml-0  overflow-scroll bg-[#222226] py-4 px-4 sm:px-6 lg:px-8">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
        className="lg:max-w-7xl mx-auto mb-4"
      >
        {Banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <div
              className="rounded-lg lg:h-[240px] flex items-center justify-center"
              style={{
                backgroundImage: `url(${banner.backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="max-w-lg p-4 sm:p-6 md:p-8 rounded-md mr-[50%]">
                <h2 className="text-xl sm:text-2xl font-semibold text-white mb-2">
                  {banner.title}
                </h2>
                <p className="text-base text-white font-light mb-4">
                  {banner.description}
                </p>
                <button className="text-white border border-white px-4 py-2 rounded-md font-semibold hover:bg-white hover:text-black transition-all">
                  {banner.buttonText}
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-semibold text-white mb-3 text-center sm:text-left">
          Nexusware Solutions: Comprehensive Tools for Fashion Innovation
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {solutions.map((solution, index) => (
            <Link
              to={solution.link}
              key={solution.title}
              className={`block ${solution.comingSoon ? "cursor-default" : ""}`}
              onClick={(e) => {
                if (solution.comingSoon) {
                  e.preventDefault();
                } else {
                  handleCatalogClick(solution.catalogType);
                }
              }}
            >
              <div className="w-full mt-4 max-w-[230px] mx-auto rounded-lg relative h-[300px]">
                <div className="mb-2 w-full h-[220px] overflow-hidden rounded-lg flex items-center justify-center bg-transparent">
                  <div className="w-[200px]">
                    <img
                      src={solution.images}
                      alt={`${solution.title}`}
                      className={`object-cover w-full h-full rounded-md ${
                        solution.comingSoon ? "" : ""
                      }`}
                    />
                  </div>
                  {solution.comingSoon && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className="bg-black bg-opacity-75 text-white px-3 py-1.5 rounded-md font-semibold">
                        Coming Soon
                      </span>
                    </div>
                  )}
                </div>
                <div className="px-2">
                  <h3 className="text-sm font-semibold text-white mb-1 text-center sm:text-left">
                    {solution.title}
                  </h3>
                  <p className="text-xs text-gray-400 mb-2 text-center sm:text-left">
                    {solution.description}
                  </p>
                  <button
                    className={`${
                      solution.comingSoon
                        ? "bg-gray-500 cursor-not-allowed"
                        : "bg-customYellow"
                    } text-black px-1.5 sm:px-3 py-1 sm:py-1.5 rounded text-sm w-full`}
                    disabled={solution.comingSoon}
                  >
                    {solution.comingSoon ? "Coming Soon" : "Get Started"}
                  </button>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const solutions = [
  {
    title: "Apparel Cataloguing",
    description:
      "Streamline your apparel catalog creation with smart tools tailored for the fashion industry.",
    images: apparelC,
    link: "/dashboard/app",
    catalogType: "apparel",
  },
  {
    title: "Product Cataloguing",
    description:
      "Streamline product cataloging with AI-powered tools for quick, professional images.",
    images: ProductC,
    link: "/dashboard/bg-fill",
    catalogType: "product",
  },
  {
    title: "Image to Video",
    description:
      "Transform images into dynamic videos with seamless transitions and professional-quality effects.",
    images: imageTovideo,
    link: "/dashboard/image-to-video",
    comingSoon: true,
  },
  {
    title: "Image Engineering",
    description:
      "Enhance digital content with powerful tools for precise visual refinements.",
    images: ImageE,
    link: "#/",
    comingSoon: true,
  },
];

const Banners = [
  {
    backgroundImage: bannerBG,
    title: "Virtual Apparel Try-On",
    description:
      "No photoshoots, no hassles—just seamless virtual try-ons. Revolutionize how you showcase and style your apparel with our AI!",
    buttonText: "Try Now",
  },
  {
    backgroundImage: bannerAI,
    title: "Instant Background Magic with AI",
    description:
      "Effortlessly remove or replace backgrounds with our AI-powered editor. Generate stunning, customized backdrops to enhance your visuals in seconds.",
    buttonText: "Try Now",
  },
];

export default Banner;
