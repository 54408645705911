import React, { useState } from "react";

const ColorBackgroundSelector = ({ handleFaceImageSelect }) => {
  const [selectedColor, setSelectedColor] = useState(null);

  // Function to create a solid color background "image"
  const createColorBackground = (color) => {
    // Create a small canvas with the solid color
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 100, 100);

    // Convert canvas to base64 image
    return canvas.toDataURL("image/png");
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    const colorImage = createColorBackground(color);
    handleFaceImageSelect(colorImage);
  };

  const colorPalette = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
    "#FFA500",
    "#800080",
    "#008000",
    "#FFC0CB",
    "#A52A2A",
    "#808080",
    "#000000",
    "#FFFFFF",
    "#FFD700",
  ];

  return (
    <div className="p-4">
      <div className="grid grid-cols-5 gap-3">
        {colorPalette.map((color, index) => (
          <div
            key={index}
            className={`w-10 h-10 rounded-full cursor-pointer transition-all duration-200 ${
              selectedColor === color
                ? "ring-2 ring-white scale-110"
                : "hover:scale-105"
            }`}
            style={{ backgroundColor: color }}
            onClick={() => handleColorSelect(color)}
          />
        ))}
      </div>

      {selectedColor && (
        <div className="mt-4 text-center">
          <div className="text-sm text-gray-300">
            Selected color: {selectedColor}
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorBackgroundSelector;
