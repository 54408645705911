import React, { useState } from "react";
import {
  Lock,
  ImageIcon,
  Square,
  RectangleHorizontal,
  RectangleVertical,
} from "lucide-react";

const Resize = ({ onResize }) => {
  const [selectedFormat, setSelectedFormat] = useState("Original");
  const [tempSelectedFormat, setTempSelectedFormat] = useState("Original");

  const formats = [
    {
      label: "Original",
      resolution: "1200×1600",
      displayWidth: 450,
      displayHeight: 600,
      exportWidth: 1200,
      exportHeight: 1600,
      icon: <ImageIcon className="w-5 h-5 text-white mr-3" />,
    },
    {
      label: "Square",
      resolution: "1080×1080",
      displayWidth: 400,
      displayHeight: 400,
      exportWidth: 1080,
      exportHeight: 1080,
      icon: <Square className="w-5 h-5 text-white mr-3" />,
    },
    {
      label: "Portrait",
      resolution: "1080×1350",
      displayWidth: 400,
      displayHeight: 500,
      exportWidth: 1080,
      exportHeight: 1350,
      icon: <RectangleVertical className="w-5 h-5 text-white mr-3" />,
    },
    {
      label: "Landscape",
      resolution: "1350×1080",
      displayWidth: 500,
      displayHeight: 400,
      exportWidth: 1350,
      exportHeight: 1080,
      icon: <RectangleHorizontal className="w-5 h-5 text-white mr-3" />,
    },
  ];

  const handleFormatSelect = (format) => {
    setTempSelectedFormat(format.label);
  };

  const handleApply = () => {
    setSelectedFormat(tempSelectedFormat);
    const selectedFormatData = formats.find(
      (f) => f.label === tempSelectedFormat,
    );
    if (onResize && selectedFormatData) {
      onResize({
        displayWidth: selectedFormatData.displayWidth,
        displayHeight: selectedFormatData.displayHeight,
        exportWidth: selectedFormatData.exportWidth,
        exportHeight: selectedFormatData.exportHeight,
      });
    }
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between border-b border-[#5f5f5f] pb-4">
        <h1>Size:</h1>
        <div className="flex text-sm font-light gap-1">
          <div>
            <h1>Width</h1>
            <p className="p-1 px-4 rounded-sm bg-grayBG2">
              {formats.find((f) => f.label === selectedFormat)?.exportWidth ||
                1200}
            </p>
          </div>
          <div>
            <h1>Height</h1>
            <p className="p-1 px-4 rounded-sm bg-grayBG2">
              {formats.find((f) => f.label === selectedFormat)?.exportHeight ||
                1600}
            </p>
          </div>
          <div>
            <h1>Ratio</h1>
            <p className="p-1 px-4 rounded-sm bg-grayBG2">
              <Lock size={20} />
            </p>
          </div>
        </div>
      </div>
      <div className="text-white p-4 rounded-xl">
        {formats.map((format, index) => (
          <div
            key={index}
            className={`flex items-center justify-between mb-3 last:mb-0 p-2 rounded cursor-pointer ${
              tempSelectedFormat === format.label ? "bg-grayBG2" : ""
            }`}
            onClick={() => handleFormatSelect(format)}
          >
            <div className="flex items-center">
              {format.icon}
              <span className="text-sm text-white">{format.label}</span>
            </div>
            <div>
              <span className="text-sm text-white">{format.resolution}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <button
          className="w-fit px-8 py-4 rounded-lg bg-grayBG2 hover:bg-gray-600 transition-colors"
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default Resize;
