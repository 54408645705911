import { Stage, Layer, Image, Transformer } from "react-konva";
import { React, useState, useEffect, useRef } from "react";
import Konva from "konva";
import { ZoomIn, ZoomOut, RotateCcw, RotateCw } from "lucide-react";
import { Line } from "react-konva";

const EditableImage = ({
  containerWidth,
  containerHeight,
  backgroundSrc,
  modelSrc,
  setStageRef,
  shadowProps,
  onMergeImage,
  blurAmount,
  blurBackground,
  maskingConfig,
  onMaskUpdate,
  ...props
}) => {
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [containerSize, setContainerSize] = useState({
    width: containerWidth || 1200,
    height: containerHeight || 1600,
  });
  const [modelProps, setModelProps] = useState({
    scale: 1,
    rotation: 0,
  });
  const imageRef = useRef(null);
  const trRef = useRef(null);
  const stageRef = useRef(null);
  const [isDraggable, setIsDraggable] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [modelImage, setModelImage] = useState(null);
  const [isBackgroundSelected, setIsBackgroundSelected] = useState(false);

  const backgroundTrRef = useRef(null);

  useEffect(() => {
    if (modelSrc) {
      const img = new window.Image();
      img.src = modelSrc;
      img.onload = () => setModelImage(img);
    }
  }, [modelSrc]);

  // Update container size when props change
  useEffect(() => {
    setContainerSize({
      width: containerWidth || 1200,
      height: containerHeight || 1600,
    });
  }, [containerWidth, containerHeight]);

  const loadImage = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.crossOrigin = "anonymous";
      img.src = src;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  useEffect(() => {
    if (backgroundSrc?.newImageUrl) {
      loadImage(backgroundSrc.newImageUrl)
        .then((img) => {
          setBackgroundImage(img);
        })
        .catch((err) => console.error(err));
    }
    if (modelSrc) {
      loadImage(modelSrc)
        .then(setModelImage)
        .catch((err) => console.error(err));
    }

    if (setStageRef) {
      setStageRef(stageRef.current);
    }
  }, [backgroundSrc, modelSrc, setStageRef]);

  useEffect(() => {
    if (backgroundRef.current && backgroundTrRef.current) {
      if (isBackgroundSelected) {
        backgroundTrRef.current.nodes([backgroundRef.current]);
      } else {
        backgroundTrRef.current.nodes([]);
      }
      backgroundTrRef.current.getLayer().batchDraw();
    }
  }, [isBackgroundSelected]);

  useEffect(() => {
    if (imageRef.current && trRef.current) {
      if (isSelected) {
        trRef.current.nodes([imageRef.current]);
      } else {
        trRef.current.nodes([]);
      }
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    setContainerSize({
      width: containerWidth,
      height: containerHeight,
    });
  }, [containerWidth, containerHeight]);

  const handleDeselect = (e) => {
    if (e.target === stageRef.current) {
      setIsSelected(false);
    }
  };

  useEffect(() => {
    if (backgroundImage && backgroundRef.current) {
      // Update blur filter when blur settings change
      if (blurBackground) {
        backgroundRef.current.cache();
        backgroundRef.current.getLayer()?.batchDraw();
      } else {
        backgroundRef.current.clearCache();
      }
    }
  }, [blurAmount, blurBackground, backgroundImage]);

  // const handleDownload = () => {
  //   if (!stageRef.current) {
  //     console.error("Stage reference is not available for download.");
  //     return;
  //   }
  //   setIsSelected(false);
  //   setTimeout(() => {
  //     const dataURL = stageRef.current.toDataURL({
  //       pixelRatio: 2,
  //     });
  //     onMergeImage(dataURL);
  //   }, 1000);
  // };

  const backgroundRef = useRef();
  const [backgroundProps, setBackgroundProps] = useState({
    x: 0,
    y: 0,
    scale: 1,
    rotation: 0,
  });

  const handleDoubleClick = () => {
    setIsDraggable(!isDraggable);

    if (!backgroundImage) return;

    const containerRatio = containerSize.width / containerSize.height;
    const imageRatio = backgroundImage.width / backgroundImage.height;

    let scale;
    if (containerRatio > imageRatio) {
      scale = containerSize.width / backgroundImage.width;
    } else {
      scale = containerSize.height / backgroundImage.height;
    }

    setBackgroundProps({
      x: 0,
      y: 0,
      scale,
    });
  };

  useEffect(() => {
    if (backgroundImage) {
      const containerRatio = containerSize.width / containerSize.height;
      const imageRatio = backgroundImage.width / backgroundImage.height;

      let scale;
      if (containerRatio > imageRatio) {
        scale = containerSize.width / backgroundImage.width;
      } else {
        scale = containerSize.height / backgroundImage.height;
      }

      setBackgroundProps({
        x: (containerSize.width - backgroundImage.width * scale) / 2,
        y: (containerSize.height - backgroundImage.height * scale) / 2,
        scale,
      });
    }
  }, [backgroundImage, containerSize]);

  const handleDragMove = (e) => {
    const { x, y } = e.target.position();
    const scaledWidth = backgroundImage.width * backgroundProps.scale;
    const scaledHeight = backgroundImage.height * backgroundProps.scale;

    const newX = Math.max(Math.min(x, 0), containerSize.width - scaledWidth);
    const newY = Math.max(Math.min(y, 0), containerSize.height - scaledHeight);

    setBackgroundProps((prev) => ({
      ...prev,
      x: newX,
      y: newY,
    }));
  };

  const handleZoom = (type, target) => {
    const ZOOM_FACTOR = 0.1;
    if (target === "model" && modelImage) {
      setModelProps((prev) => ({
        ...prev,
        scale: Math.max(
          0.1,
          prev.scale + (type === "in" ? ZOOM_FACTOR : -ZOOM_FACTOR),
        ),
      }));
    } else if (target === "background" && backgroundImage) {
      setBackgroundProps((prev) => ({
        ...prev,
        scale: Math.max(
          0.1,
          prev.scale + (type === "in" ? ZOOM_FACTOR : -ZOOM_FACTOR),
        ),
      }));
    }
  };

  const handleRotate = (direction, target) => {
    const ROTATION_ANGLE = 15;
    if (target === "model" && modelImage) {
      setModelProps((prev) => ({
        ...prev,
        rotation:
          prev.rotation +
          (direction === "cw" ? ROTATION_ANGLE : -ROTATION_ANGLE),
      }));
    } else if (target === "background" && backgroundImage) {
      setBackgroundProps((prev) => ({
        ...prev,
        rotation:
          prev.rotation +
          (direction === "cw" ? ROTATION_ANGLE : -ROTATION_ANGLE),
      }));
    }
  };

  const handleMouseDown = (e) => {
    if (!maskingConfig) return;
    setIsDrawing(true);
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { points: [pos.x, pos.y], mode: maskingConfig.mode }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing || !maskingConfig) return;
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    lines.splice(lines.length - 1, 1, lastLine);
    setLines([...lines]);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    if (onMaskUpdate) {
      onMaskUpdate(lines);
    }
  };

  const ImageControls = ({ target }) => (
    <div className="flex flex-col gap-2 p-2">
      <button
        onClick={() => handleZoom("in", target)}
        className="p-2 rounded-full hover:bg-gray-700 transition-colors"
      >
        <ZoomIn />
      </button>
      <button
        onClick={() => handleZoom("out", target)}
        className="p-2 rounded-full hover:bg-gray-700 transition-colors"
      >
        <ZoomOut />
      </button>
      <div className="border border-[#5f5f5f] my-2"></div>
      <button
        onClick={() => handleRotate("ccw", target)}
        className="p-2  rounded-full hover:bg-gray-700 transition-colors"
      >
        <RotateCcw />
      </button>
      <button
        onClick={() => handleRotate("cw", target)}
        className="p-2  rounded-full hover:bg-gray-700 transition-colors"
      >
        <RotateCw />
      </button>
    </div>
  );

  return (
    <div className="h-fit relative w-full">
      <div className="mx-auto w-fit border border-[#313131] flex flex-col items-center justify-center">
        <Stage
          ref={stageRef}
          width={containerSize.width}
          height={containerSize.height}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          pixelRatio={window.devicePixelRatio || 1}
        >
          <Layer>
            {backgroundImage && (
              <Image
                ref={backgroundRef}
                image={backgroundImage}
                x={backgroundProps.x}
                y={backgroundProps.y}
                width={backgroundImage.width * backgroundProps.scale}
                height={backgroundImage.height * backgroundProps.scale}
                rotation={backgroundProps.rotation}
                draggable={isDraggable}
                onDragMove={handleDragMove}
                onDblClick={handleDoubleClick}
                onClick={(e) => {
                  e.cancelBubble = true;
                  setIsBackgroundSelected(true);
                  setIsSelected(false);
                }}
                filters={blurBackground ? [Konva.Filters.Blur] : []}
                blurRadius={blurBackground ? blurAmount / 2 : 0}
              />
            )}
            {modelImage && (
              <Image
                ref={imageRef}
                image={modelImage}
                draggable
                x={containerSize.width * 0.1}
                y={containerSize.height * 0.1}
                width={
                  (containerSize.height * 0.8 * modelImage.width) /
                  modelImage.height
                }
                height={containerSize.height * 0.8}
                scaleX={modelProps.scale}
                scaleY={modelProps.scale}
                rotation={modelProps.rotation}
                shadowColor={shadowProps?.shadowColor}
                shadowBlur={shadowProps?.shadowBlur}
                shadowOffsetX={shadowProps?.shadowOffsetX}
                shadowOffsetY={shadowProps?.shadowOffsetY}
                shadowOpacity={shadowProps?.shadowOpacity}
                onClick={(e) => {
                  e.cancelBubble = true;
                  setIsSelected(true);
                  setIsBackgroundSelected(false);
                }}
                onTap={(e) => {
                  e.cancelBubble = true;
                  setIsSelected(true);
                  setIsBackgroundSelected(false);
                }}
              />
            )}
            {lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke={
                  line.mode === "eraser"
                    ? "rgba(0,0,0,0.6)"
                    : "rgba(255,255,255,0.6)"
                }
                strokeWidth={maskingConfig?.brushSize || 20}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={
                  line.mode === "eraser" ? "destination-out" : "source-over"
                }
              />
            ))}
            <Transformer
              ref={trRef}
              anchorSize={6}
              enabledAnchors={[
                "top-left",
                "top-right",
                "bottom-left",
                "bottom-right",
              ]}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                if (newBox.width < 30 || newBox.height < 30) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          </Layer>
        </Stage>
      </div>
      <div className="absolute top-0 right-20 text-white rounded-lg mt-4 p-2 flex flex-col gap-2">
        {isSelected && (
          <div className="flex flex-col items-center">
            <ImageControls target="model" />
          </div>
        )}
        {isBackgroundSelected && (
          <div className="flex flex-col items-center">
            <ImageControls target="background" />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableImage;
