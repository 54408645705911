import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Camera, Lock, Mail, Phone, User, X, UserCircle2 } from "lucide-react";
import {
  getProfile,
  updateProfilePicture,
  updateProfileName,
  updateProfilePhone,
} from "../../api/authApi";
import { toast } from "react-toastify";

const Profile = () => {
  const [profileImage, setProfileImage] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    profile_image: "",
  });
  const [newPassword, setNewPassword] = useState({
    current: "",
    new: "",
    confirm: "",
  });

  console.log(profileData);

  const fetchProfile = async () => {
    try {
      const userProfile = await getProfile();
      if (userProfile.success) {
        setProfileData(userProfile.data);
        if (userProfile.data.profile_image) {
          setProfileImage(userProfile.data.profile_image);
        }
      }
    } catch (error) {
      console.error("Failed to fetch profile: ", error);
      toast.error("Failed to load profile data");
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleImageUpload = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Validate file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size should be less than 5MB");
        return;
      }

      // Validate file type
      if (!file.type.startsWith("image/")) {
        toast.error("Please upload an image file");
        return;
      }

      setIsUploading(true);

      try {
        // Create image URL immediately
        const imageUrl = URL.createObjectURL(file);

        // Upload to server
        const response = await updateProfilePicture(file);

        if (response.success) {
          // Set the image URL immediately
          setProfileImage(imageUrl);

          // Update profile data with server response
          setProfileData((prev) => ({
            ...prev,
            profile_picture: response.data.profile_picture,
          }));

          toast.success("Profile picture updated successfully");
        } else {
          throw new Error(response.error || "Failed to upload image");
        }
      } catch (error) {
        toast.error(error.message || "Failed to upload image");
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleRemoveImage = async () => {
    setIsUploading(true);

    try {
      const response = await updateProfilePicture(""); // Try empty string instead of null

      if (response.success) {
        // Immediately fetch fresh profile data
        await fetchProfile();
        setProfileImage("");
        setProfileData((prev) => ({
          ...prev,
          profile_picture: "", // Ensure this matches API response
        }));
        toast.success("Profile picture removed successfully");
      } else {
        throw new Error(response.error || "Failed to remove image");
      }
    } catch (error) {
      toast.error(error.message || "Failed to remove image");
    } finally {
      setIsUploading(false);
    }
  };

  const handleUpdateName = async () => {
    // API call to update name
    try {
      const response = await updateProfileName(
        profileData.first_name,
        profileData.last_name,
      );

      if (response.success) {
        await fetchProfile();
        setIsEditingName(false);

        toast.success("Name updated successfully");
      } else {
        throw new Error(response.error || "Failed to update name");
      }
    } catch (error) {
      toast.error(error.message || "Failed to update name");
    } finally {
      setIsUploading(false);
    }
  };

  const handleUpdateEmail = () => {
    // API call to update email
    setIsEditingEmail(false);

    toast.success("Email updated successfully");
  };

  const validatePhone = (phoneNumber) => {
    // Regex for phone number validation
    const phoneRegex =
      /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    // Minimum and maximum allowed lengths
    const MIN_LENGTH = 7;
    const MAX_LENGTH = 15;

    // Remove all non-digit characters
    const digitsOnly = phoneNumber.replace(/\D/g, "");

    // Regex validation
    if (!phoneRegex.test(phoneNumber)) {
      return {
        isValid: false,
        message: "❌ Please enter a valid phone number.",
      };
    }

    // Length validation
    if (digitsOnly.length < MIN_LENGTH || digitsOnly.length > MAX_LENGTH) {
      return {
        isValid: false,
        message: `❌ Phone number must be between ${MIN_LENGTH} and ${MAX_LENGTH} digits.`,
      };
    }

    // If both validations pass
    return {
      isValid: true,
      message: "✅ Phone number is valid!",
    };
  };

  const handleUpdatePhone = async () => {
    try {
      const validation = validatePhone(profileData.phone_number);
      if (!validation.isValid) {
        toast.error(validation.message);
        return;
      }

      const response = await updateProfilePhone(profileData.phone_number);

      if (response.success) {
        await fetchProfile();
        setIsEditingPhone(false);
        toast.success("Phone updated successfully");
      } else {
        throw new Error(response.error || "Failed to update Phone");
      }
    } catch (error) {
      toast.error(error.message || "Failed to update Phone");
    } finally {
      setIsUploading(false);
    }
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    // API call to update password
    setShowPasswordModal(false);
    setNewPassword({ current: "", new: "", confirm: "" });

    toast.success("Password updated successfully");
  };

  return (
    <div className="container mx-auto text-white px-4 py-8">
      <div className="p-8 md:p-12">
        <h2 className="text-3xl text-center font-bold text-purple-600 mb-24">
          My Profile
        </h2>

        <div className="flex flex-col lg:flex-row lg:space-x-12">
          <div className="lg:w-1/3 flex flex-col items-center mb-8 lg:mb-0">
            <div className="relative w-64 h-64 mb-6">
              {isUploading && (
                <div className="absolute inset-0 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-600"></div>
                </div>
              )}

              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="w-full h-full rounded-full object-cover border-4 border-purple-200"
                />
              ) : (
                <div className="w-full h-full rounded-full bg-purple-100 flex items-center justify-center">
                  <UserCircle2 className="w-32 h-32 text-purple-300" />
                </div>
              )}

              <label
                htmlFor="profile-image-upload"
                className={`absolute bottom-4 right-4 p-3 bg-purple-600 text-white rounded-full cursor-pointer hover:bg-purple-700 transition shadow-lg ${
                  isUploading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <Camera className="w-6 h-6" />
              </label>
              <input
                id="profile-image-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
                disabled={isUploading}
              />
            </div>

            {profileImage && (
              <button
                onClick={handleRemoveImage}
                className={`mt-4 px-6 py-2 text-red-500 border border-red-500 rounded-lg hover:bg-red-50 transition flex items-center text-lg ${
                  isUploading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isUploading}
              >
                <X className="w-5 h-5 mr-2" /> Remove Photo
              </button>
            )}
          </div>

          <div className="lg:w-2/3 space-y-8">
            <div className="space-y-4">
              <label className="text-base font-medium flex items-center gap-3">
                <Mail className="w-6 h-6 text-purple-600" /> Email Address
              </label>

              <div className="flex py-2 justify-between items-center">
                <span className="text-base">{profileData.email}</span>
              </div>
            </div>

            <div className="space-y-4">
              <label className="text-base font-medium flex items-center gap-3">
                <User className="w-6 h-6 text-purple-600" /> Full Name
              </label>
              {isEditingName ? (
                <div className="flex flex-col sm:flex-row gap-4">
                  <input
                    type="text"
                    value={profileData.first_name}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        first_name: e.target.value,
                      })
                    }
                    className="flex-1 px-4 py-3 border rounded-md text-black focus:ring-2 focus:ring-purple-600 outline-none text-lg"
                    placeholder="First Name"
                  />
                  <input
                    type="text"
                    value={profileData.last_name}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        last_name: e.target.value,
                      })
                    }
                    className="flex-1 px-4 py-3 border rounded-md text-black focus:ring-2 focus:ring-purple-600 outline-none text-lg"
                    placeholder="Last Name"
                  />
                  <button
                    onClick={handleUpdateName}
                    className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition text-lg"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div className="flex justify-between items-center">
                  <span className="text-lg">{`${profileData.first_name} ${profileData.last_name}`}</span>
                  <button
                    onClick={() => setIsEditingName(true)}
                    className="px-4 py-2 text-purple-600 hover:bg-purple-50 rounded-md transition text-lg"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>

            <div className="space-y-4">
              <label className="text-base font-medium flex items-center gap-3">
                <Phone className="w-6 h-6 text-purple-600" /> Phone Number
              </label>
              {isEditingPhone ? (
                <div className="flex flex-col sm:flex-row gap-4">
                  <input
                    type="tel"
                    value={profileData.phone_number}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        phone_number: e.target.value,
                      })
                    }
                    className="flex-1 px-4 py-3 border rounded-md text-black focus:ring-2 focus:ring-purple-600 outline-none text-lg"
                  />
                  <button
                    onClick={handleUpdatePhone}
                    className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition text-lg"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div className="flex justify-between items-center">
                  <span className="text-base">
                    {profileData.phone_number || "Not set"}
                  </span>
                  <button
                    onClick={() => setIsEditingPhone(true)}
                    className="px-4 py-2 text-purple-600 hover:bg-purple-50 rounded-md transition text-lg"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>

            <button
              onClick={() => setShowPasswordModal(true)}
              className="w-full px-6 py-3 mt-8 border-2 border-purple-600 text-purple-600 rounded-lg hover:bg-purple-50 transition flex items-center justify-center text-base font-medium"
            >
              <Lock className="w-5 h-5 mr-2" /> Change Password
            </button>
          </div>
        </div>
      </div>

      {showPasswordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-2xl font-semibold">Change Password</h3>
              <button
                onClick={() => setShowPasswordModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
            <form onSubmit={handlePasswordChange} className="space-y-6">
              <div>
                <label className="text-lg font-medium">Current Password</label>
                <input
                  type="password"
                  value={newPassword.current}
                  onChange={(e) =>
                    setNewPassword((prev) => ({
                      ...prev,
                      current: e.target.value,
                    }))
                  }
                  className="w-full px-4 py-3 border rounded-md focus:ring-2 focus:ring-purple-600 outline-none mt-2 text-lg"
                />
              </div>
              <div>
                <label className="text-lg font-medium">New Password</label>
                <input
                  type="password"
                  value={newPassword.new}
                  onChange={(e) =>
                    setNewPassword((prev) => ({ ...prev, new: e.target.value }))
                  }
                  className="w-full px-4 py-3 border rounded-md focus:ring-2 focus:ring-purple-600 outline-none mt-2 text-lg"
                />
              </div>
              <div>
                <label className="text-lg font-medium">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  value={newPassword.confirm}
                  onChange={(e) =>
                    setNewPassword((prev) => ({
                      ...prev,
                      confirm: e.target.value,
                    }))
                  }
                  className="w-full px-4 py-3 border rounded-md focus:ring-2 focus:ring-purple-600 outline-none mt-2 text-lg"
                />
              </div>
              <div className="flex gap-4 mt-8">
                <button
                  type="button"
                  onClick={() => setShowPasswordModal(false)}
                  className="flex-1 px-6 py-3 border-2 border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition text-lg font-medium"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex-1 px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition text-lg font-medium"
                >
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
