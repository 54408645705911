import React, { useState } from "react";

const ShadowOptions = ({ onShadowPropsChange }) => {
  const [activeTab, setActiveTab] = useState("dropShadow");
  const [shadowProps, setShadowProps] = useState({
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowBlur: 5,
    shadowOpacity: 0.6,
    shadowOffsetX: 28,
    shadowOffsetY: -2,
  });

  const handleTabChange = (tab) => {
    setActiveTab(tab);

    const newShadowProps =
      tab === "dropShadow"
        ? {
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowBlur: 5,
            shadowOpacity: 0.6,
            shadowOffsetX: 28,
            shadowOffsetY: -2,
          }
        : {
            shadowColor: "rgba(0, 0, 0, 0)",
            shadowBlur: 0,
            shadowOpacity: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
          };

    setShadowProps(newShadowProps);
    onShadowPropsChange(newShadowProps);
  };

  const updateShadowProps = (key, value) => {
    const newShadowProps = { ...shadowProps, [key]: value };
    setShadowProps(newShadowProps);
    onShadowPropsChange(newShadowProps);
  };

  return (
    <div className="mt-2 bg-transparent">
      {/* tabs */}
      <div className="flex bg-black p-1 items-center justify-around rounded-2xl mb-2">
        <button
          onClick={() => handleTabChange("dropShadow")}
          className={`px-4 py-1 ${activeTab === "dropShadow" ? "bg-generate-gradient" : "hover:bg-grayBG"} transition-all duration-200 rounded-2xl`}
        >
          Drop Shadow
        </button>
        {/* <button
          onClick={() => handleTabChange("aiAngle")}
          className={`px-4 py-1 ${activeTab === "aiAngle" ? "bg-generate-gradient" : "hover:bg-grayBG"} transition-all duration-200 rounded-2xl`}
        >
          AI Shadow
        </button> */}
      </div>

      {activeTab === "dropShadow" && (
        <div className="mt-4 flex flex-col">
          <div className="m-2 flex items-center justify-between w-full">
            <label htmlFor="horizontal" className="text-white w-24">
              Horizontal
            </label>
            <div className="flex-1 flex justify-between w-full">
              <input
                id="horizontal"
                type="range"
                min={-50}
                max={50}
                value={shadowProps.shadowOffsetX}
                onChange={(e) =>
                  updateShadowProps(
                    "shadowOffsetX",
                    parseInt(e.target.value, 10),
                  )
                }
                className="w-[160px] appearance-none bg-transparent 
                [&::-webkit-slider-thumb]:appearance-none 
                [&::-webkit-slider-thumb]:h-4 
                [&::-webkit-slider-thumb]:w-4 
                [&::-webkit-slider-thumb]:bg-purple-500 
                [&::-webkit-slider-thumb]:rounded-full 
                [&::-webkit-slider-thumb]:relative 
                [&::-webkit-slider-thumb]:-top-1.5 
                [&::-webkit-slider-runnable-track]:bg-white 
                [&::-webkit-slider-runnable-track]:h-1 
                [&::-webkit-slider-runnable-track]:rounded-full"
              />
              <div>
                <span className="ml-2 text-white">
                  {shadowProps.shadowOffsetX}px
                </span>
              </div>
            </div>
          </div>

          <div className="m-2 flex items-center justify-between w-full">
            <label htmlFor="vertical" className="text-white w-24">
              Vertical
            </label>
            <div className="flex-1 flex justify-between w-full">
              <input
                id="vertical"
                type="range"
                min={-50}
                max={50}
                value={shadowProps.shadowOffsetY}
                onChange={(e) =>
                  updateShadowProps(
                    "shadowOffsetY",
                    parseInt(e.target.value, 10),
                  )
                }
                className="w-[160px] appearance-none bg-transparent 
                [&::-webkit-slider-thumb]:appearance-none 
                [&::-webkit-slider-thumb]:h-4 
                [&::-webkit-slider-thumb]:w-4 
                [&::-webkit-slider-thumb]:bg-purple-500 
                [&::-webkit-slider-thumb]:rounded-full 
                [&::-webkit-slider-thumb]:relative 
                [&::-webkit-slider-thumb]:-top-1.5 
                [&::-webkit-slider-runnable-track]:bg-white 
                [&::-webkit-slider-runnable-track]:h-1 
                [&::-webkit-slider-runnable-track]:rounded-full"
              />
              <div>
                {" "}
                <span className="ml-2 text-white">
                  {shadowProps.shadowOffsetY}px
                </span>
              </div>
            </div>
          </div>

          <div className="m-2 flex items-center justify-between w-full">
            <label htmlFor="blur" className="text-white w-24">
              Blur
            </label>
            <div className="flex-1 flex justify-between w-full">
              <input
                id="blur"
                type="range"
                min={0}
                max={100}
                value={shadowProps.shadowBlur}
                onChange={(e) =>
                  updateShadowProps("shadowBlur", parseInt(e.target.value, 10))
                }
                className="w-[160px] appearance-none bg-transparent 
  [&::-webkit-slider-thumb]:appearance-none 
  [&::-webkit-slider-thumb]:h-4 
  [&::-webkit-slider-thumb]:w-4 
  [&::-webkit-slider-thumb]:bg-purple-500 
  [&::-webkit-slider-thumb]:rounded-full 
  [&::-webkit-slider-thumb]:relative 
  [&::-webkit-slider-thumb]:-top-1.5 
  [&::-webkit-slider-runnable-track]:bg-white 
  [&::-webkit-slider-runnable-track]:h-1 
  [&::-webkit-slider-runnable-track]:rounded-full"
              />
              <div>
                <span className="ml-2 text-white">
                  {shadowProps.shadowBlur}
                </span>
              </div>
            </div>
          </div>

          <div className="m-2 flex items-center justify-between w-full">
            <label htmlFor="opacity" className="text-white w-24">
              Opacity
            </label>
            <div className="flex-1 flex justify-between w-full">
              <input
                id="opacity"
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={shadowProps.shadowOpacity}
                onChange={(e) =>
                  updateShadowProps("shadowOpacity", parseFloat(e.target.value))
                }
                className="w-[160px] appearance-none bg-transparent 
                [&::-webkit-slider-thumb]:appearance-none 
                [&::-webkit-slider-thumb]:h-4 
                [&::-webkit-slider-thumb]:w-4 
                [&::-webkit-slider-thumb]:bg-purple-500 
                [&::-webkit-slider-thumb]:rounded-full 
                [&::-webkit-slider-thumb]:relative 
                [&::-webkit-slider-thumb]:-top-1.5 
                [&::-webkit-slider-runnable-track]:bg-white 
                [&::-webkit-slider-runnable-track]:h-1 
                [&::-webkit-slider-runnable-track]:rounded-full"
              />
              <div>
                {" "}
                <span className="ml-2 text-white">
                  {(shadowProps.shadowOpacity * 100).toFixed(0)}%
                </span>
              </div>
            </div>
          </div>
          <div className="m-2">
            <div className="flex items-center space-x-2 ml-4">
              {[
                "#FFFFFF",
                "#8B0000",
                "#0074D9",
                "#A0522D",
                "#000000",
                "#008080",
              ].map((color, index) => (
                <button
                  key={index}
                  className="w-8 h-8 rounded-full border-2 border-white"
                  style={{
                    background: color,
                    backgroundColor: color.includes("gradient")
                      ? undefined
                      : color,
                  }}
                  onClick={() =>
                    updateShadowProps(
                      "shadowColor",
                      color.includes("gradient") ? "transparent" : color,
                    )
                  }
                />
              ))}
              <div className="relative">
                <button
                  className="w-8 h-8 rounded-full mt-2"
                  style={{
                    background:
                      "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
                  }}
                  onClick={() =>
                    document.getElementById("customColorPicker").click()
                  }
                ></button>
                <input
                  type="color"
                  id="customColorPicker"
                  className="absolute inset-0 w-0 h-0 opacity-0"
                  onChange={(e) =>
                    updateShadowProps("shadowColor", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === "aiAngle" && (
        <div className="mt-4 flex flex-col">
          <div className="mt-2  rounded-md text-white p-2">
            <div className="mb-2 flex items-center justify-between w-full">
              <label className="text-white w-24">Light Direction</label>
              <div className="flex-1 flex ">
                <input
                  type="range"
                  min="0"
                  max="360"
                  className="w-[160px] appearance-none bg-transparent 
                  [&::-webkit-slider-thumb]:appearance-none 
                  [&::-webkit-slider-thumb]:h-4 
                  [&::-webkit-slider-thumb]:w-4 
                  [&::-webkit-slider-thumb]:bg-purple-500 
                  [&::-webkit-slider-thumb]:rounded-full 
                  [&::-webkit-slider-thumb]:relative 
                  [&::-webkit-slider-thumb]:-top-1.5 
                  [&::-webkit-slider-runnable-track]:bg-white 
                  [&::-webkit-slider-runnable-track]:h-1 
                  [&::-webkit-slider-runnable-track]:rounded-full"
                />
                <div>
                  <span className="ml-2">0°</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 mb-2 flex items-center justify-between w-full">
            <label className="text-white w-24">Angle Opacity</label>
            <div className="flex-1 flex">
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                className="w-[160px] appearance-none bg-transparent 
                [&::-webkit-slider-thumb]:appearance-none 
                [&::-webkit-slider-thumb]:h-4 
                [&::-webkit-slider-thumb]:w-4 
                [&::-webkit-slider-thumb]:bg-purple-500 
                [&::-webkit-slider-thumb]:rounded-full 
                [&::-webkit-slider-thumb]:relative 
                [&::-webkit-slider-thumb]:-top-1.5 
                [&::-webkit-slider-runnable-track]:bg-white 
                [&::-webkit-slider-runnable-track]:h-1 
                [&::-webkit-slider-runnable-track]:rounded-full"
              />
              <div>
                <span className="ml-2">0%</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShadowOptions;
