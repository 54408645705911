import { createSlice } from "@reduxjs/toolkit";

// Get initial catalog type from localStorage or use default
const getInitialCatalogType = () => {
  const savedType = localStorage.getItem("catalogType");
  if (savedType) {
    return {
      apparelCatalog: savedType === "apparel",
      productCatalog: savedType === "product",
    };
  }
  return {
    apparelCatalog: true,
    productCatalog: false,
  };
};

const initialState = {
  FACE_SWAP: {},
  APPAREL_SWAP: {},
  BG_SWAP: {},
  MODEL_GEN: {},
  BG_GEN: {},
  Quick_Catalog: {},
  DESC_GEN: {
    file: null,
    garmentDetails: null,
    error: null,
    isLoading: false,
    previewUrl: null,
  },
  sidebar: {
    isOpen: true,
  },
  catalogType: getInitialCatalogType(),
};

const studioSlice = createSlice({
  name: "studio",
  initialState,
  reducers: {
    updateTabState: (state, action) => {
      const { tabId, newState } = action.payload;
      state[tabId] = { ...state[tabId], ...newState };
    },
    toggleSidebar: (state) => {
      state.sidebar.isOpen = !state.sidebar.isOpen;
    },
    setSidebarState: (state, action) => {
      state.sidebar.isOpen = action.payload;
    },
    setCatalogType: (state, action) => {
      // Save to localStorage when updating
      localStorage.setItem("catalogType", action.payload);
      state.catalogType = {
        apparelCatalog: action.payload === "apparel",
        productCatalog: action.payload === "product",
      };
    },
  },
});

export const {
  updateTabState,
  toggleSidebar,
  setSidebarState,
  setCatalogType,
} = studioSlice.actions;
export default studioSlice.reducer;
