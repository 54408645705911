import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getBackdropImages,
  getFullModelImages,
} from "../../../api/statics.jsx";
import {
  postBackgroundRemove,
  pollForTaskFinish,
} from "../../../api/modelsApi.jsx";
import { taskStatus } from "../../../config/constants.js";
import ImageProcessor from "../ImgProcessorProduct.jsx";
import { AlertMessage } from "../../Utils/AlertMessage.jsx";
import { updateTabState } from "../../../redux/studioSlice.js";

const BgSwap = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.BG_SWAP);

  const [selectedFace, setSelectedFace] = useState({
    blobImage: null,
    blobImageUrl: state?.selectedFace?.blobImageUrl || "",
  });

  const [selectedModel, setSelectedModel] = useState({
    blobImage: null,
    blobImageUrl: state?.selectedModel?.blobImageUrl || "",
  });

  const [appliedFilter, setAppliedFilter] = useState(
    state?.appliedFilter || null,
  );
  const [warningMessage, setWarningMessage] = useState(
    state?.warningMessage || "",
  );
  const [faceImages, setFaceImages] = useState(state?.faceImages || []);
  const [modelImages, setModelImages] = useState(state?.modelImages || []);
  const [isDownloadReady, setIsDownloadReady] = useState(
    state?.isDownloadReady || false,
  );
  const [alertVisible, setAlertVisible] = useState(
    state?.alertVisible || false,
  );
  const [isRunning, setIsRunning] = useState(state?.isRunning || false);

  useEffect(() => {
    const fetchImages = async () => {
      if (faceImages.length === 0 || modelImages.length === 0) {
        try {
          const [faceImageData, modelImageData] = await Promise.all([
            getBackdropImages(),
            getFullModelImages(),
          ]);

          const faceImageUrls = faceImageData.map((image) => image.file);
          const modelImageUrls = modelImageData.map((image) => image.file);

          setFaceImages(faceImageUrls);
          setModelImages(modelImageUrls);

          dispatch(
            updateTabState({
              tabId: "BG_FILL",
              newState: {
                faceImages: faceImageUrls,
                modelImages: modelImageUrls,
              },
            }),
          );
        } catch (error) {
          console.error("Error fetching images:", error);
          setWarningMessage("Failed to load images.");
          setAlertVisible(true);
        }
      }
    };
    fetchImages();
  }, [faceImages.length, modelImages.length, dispatch]);

  const handleFaceSelect = (face, blobImage = null) => {
    setSelectedFace({
      blobImage: blobImage || face,
      blobImageUrl: face,
    });
    dispatch(
      updateTabState({
        tabId: "BG_FILL",
        newState: {
          selectedFace: {
            blobImageUrl: face,
          },
        },
      }),
    );
  };

  const handleModelSelect = (model, blobImage = null) => {
    setSelectedModel({
      blobImage: blobImage || model,
      blobImageUrl: model,
    });
    dispatch(
      updateTabState({
        tabId: "BG_FILL",
        newState: {
          selectedModel: {
            blobImageUrl: model,
          },
        },
      }),
    );
  };

  const handleFaceUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      const blobUrl = URL.createObjectURL(blobImage);
      handleFaceSelect(blobUrl, blobImage);
      setAppliedFilter(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
    }
  };

  const handleModelUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      const blobUrl = URL.createObjectURL(blobImage);
      handleModelSelect(blobUrl, blobImage);
      setAppliedFilter(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
    }
  };

  const handleRun = async () => {
    if (isRunning) {
      setWarningMessage("Task already in progress.");
      setAlertVisible(true);
      return;
    }

    if (!selectedFace.blobImage || !selectedModel.blobImage) {
      setWarningMessage("Please select both face and model images.");
      setAlertVisible(true);
      return;
    }

    setIsRunning(true);
    try {
      const BgSwapResponse = await postBackgroundRemove(
        selectedFace.blobImage,
        selectedModel.blobImageUrl,
      );
      const taskId = BgSwapResponse.task_id;
      let statusObj = await pollForTaskFinish(taskId);

      if (statusObj.status === taskStatus.SUCCESS) {
        setAppliedFilter(statusObj.output);
        setIsDownloadReady(true);
        dispatch(
          updateTabState({
            tabId: "BG_FILL",
            newState: {
              appliedFilter: statusObj.output,
              isDownloadReady: true,
            },
          }),
        );
      } else if (
        statusObj.status === taskStatus.FAILED ||
        statusObj.status === taskStatus.EMPTY_OUTPUT
      ) {
        throw new Error("Failed to process image!");
      } else {
        throw new Error(`Unknown task status: ${statusObj.status}`);
      }
    } catch (error) {
      setWarningMessage(error.message);
      setAlertVisible(true);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <>
      <ImageProcessor
        Header=""
        title="Source Image"
        images={faceImages}
        modelImages={modelImages}
        uploadTitle="Target Image"
        onFaceSelect={handleFaceSelect}
        onModelSelect={handleModelSelect}
        onFaceUpload={handleFaceUpload}
        onModelUpload={handleModelUpload}
        onRun={handleRun}
        selectedFace={selectedFace}
        selectedModel={selectedModel}
        appliedFilter={appliedFilter}
        isRunning={isRunning}
        isDownloadReady={isDownloadReady}
        type_run="Swap BACKGROUND"
        isType={"BackgroundFill"}
      />
      {alertVisible && warningMessage && (
        <AlertMessage
          message={warningMessage}
          onClose={() => {
            setAlertVisible(false);
            dispatch(
              updateTabState({
                tabId: "BG_FILL",
                newState: { alertVisible: false },
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default BgSwap;
