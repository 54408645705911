import React, { useState, useEffect } from "react";
import {
  Image as ImageIcon,
  X,
  ChevronDown,
  ChevronUp,
  Brush,
  MousePointerClick,
} from "lucide-react";
import { useSelector } from "react-redux";
// import EditMasking from "../../ImageProcessing/EditMasking.jsx";
import RightDock from "../ImageProcessing/RightDock.jsx";
import Loader from "../ImageProcessing/BackgroundSwap/Loader.jsx";
import FileUploader from "../ImageProcessing/FileUploader.jsx";
import ImageSection from "../ImageProcessing/ImageSection.jsx";
import FullscreenImageModal from "../ImageProcessing/FullScreenImageModal.jsx";
import { BrushSizeSlider } from "../ImageProcessing/EditMasking.jsx";
import ToggleButton from "../UIComponents/ToggleButton.jsx";

const ImageProcessor = ({
  targetTitle,
  images,
  modelImages,
  sourceTitle,
  onFaceSelect,
  onModelSelect,
  onFaceUpload,
  onModelUpload,
  onRun,
  selectedFace,
  selectedModel,
  outputImage,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
  isType,
}) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [modelImage, setModelImage] = useState({});
  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);
  const [isPropsOpen, setPropsOpen] = useState(false);
  const [mode, setMode] = useState("Brush");
  const [brushSize, setBrushSize] = useState(10);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`${isSidebarOpen ? "ml-[200px]" : ""} mt-16 bg-[#222226] transition-all duration-200 rounded-xl`}
    >
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col relative px-2 ml-6 my-2 overflow-auto lg:w-[720px] h-screen">
          {isType === "Prop fill" && (
            <PropFill
              faceImage={faceImage}
              setFaceImage={setFaceImage}
              sourceTitle={sourceTitle}
              images={images}
              handleFaceImageSelect={handleFaceImageSelect}
              handleFaceImageUpload={handleFaceImageUpload}
              setFullscreenImage={setFullscreenImage}
            />
          )}
          {isType === "ObjectRemover" && (
            <ObjectRemove
              sourceTitle={sourceTitle}
              faceImage={faceImage}
              setFaceImage={setFaceImage}
              images={images}
              handleFaceImageSelect={handleFaceImageSelect}
              handleFaceImageUpload={handleFaceImageUpload}
              renderLoadingPlaceholders={renderLoadingPlaceholders}
              isPropsOpen={isPropsOpen}
              setPropsOpen={setPropsOpen}
              mode={mode}
              setMode={setMode}
              brushSize={brushSize}
              setBrushSize={setBrushSize}
              openFullscreenImage={openFullscreenImage}
            />
          )}

          {isType === "ProductTryon" && (
            <ProductTryon
              sourceTitle={sourceTitle}
              targetTitle={targetTitle}
              faceImage={faceImage}
              setFaceImage={setFaceImage}
              modelImage={modelImage}
              setModelImage={setModelImage}
              images={images}
              modelImages={modelImages}
              handleFaceImageSelect={handleFaceImageSelect}
              handleFaceImageUpload={handleFaceImageUpload}
              handleModelImageSelect={handleModelImageSelect}
              handleModelImageUpload={handleModelImageUpload}
              renderLoadingPlaceholders={renderLoadingPlaceholders}
              openFullscreenImage={openFullscreenImage}
            />
          )}
          {isType === "BackgroundFill" && (
            <BackgroundFill
              modelImage={modelImage}
              setModelImage={setModelImage}
              handleModelImageUpload={handleModelImageUpload}
              handleModelReset={() => setModelImage({})}
              predefinedPrompts={["Forest", "Beach", "City", "Abstract Art"]}
            />
          )}
        </div>
        <div className="w-full min-h-screen bg-black">
          <div className="flex flex-col items-center justify-center md:flex-row gap-4">
            <div className="w-full md:w-1/2 relative rounded-lg py-4">
              <div className="aspect-square w-full mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <Loader />
                    </div>
                  </div>
                ) : outputImage ? (
                  <img
                    src={outputImage}
                    alt="filtered"
                    className="w-full h-full object-contain rounded-lg cursor-pointer"
                    onClick={() => openFullscreenImage(outputImage)}
                  />
                ) : (
                  <div className="bg-[#333537] p-4 h-full flex flex-col items-center justify-center rounded-lg">
                    <ImageIcon size={200} className="text-gray-300" />
                    <h1 className="text-sm text-gray-500">
                      Nothing to see here yet!
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <RightDock
          onRun={onRun}
          selectedFace={selectedFace}
          selectedModel={selectedModel}
          isRunning={isRunning}
          outputImage={outputImage}
          feature={"face-swap"}
        />

        {/* Fullscreen Modal */}
        <FullscreenImageModal
          imageUrl={fullscreenImage}
          onClose={closeFullscreenImage}
        />
      </div>
    </div>
  );
};

export default ImageProcessor;
export const PropFill = ({
  faceImage,
  setFaceImage,
  sourceTitle,
  images,
  handleFaceImageSelect,
  handleFaceImageUpload,
  renderLoadingPlaceholders,
  setFullscreenImage,
}) => {
  const [isPropsOpen, setPropsOpen] = useState(false);
  const [mode, setMode] = useState("Brush");
  const [brushSize, setBrushSize] = useState(1);
  const [prompt, setPrompt] = useState("");

  return (
    <>
      {faceImage?.newImageUrl ? (
        <div className="ml-4 relative">
          <h1 className="text-white my-4">{sourceTitle}</h1>
          <div
            className="w-[150px] h-[150px] relative bg-grayBG2 rounded-lg mb-4"
            onClick={() => setFullscreenImage(faceImage.newImageUrl)}
          >
            <img
              src={faceImage.newImageUrl}
              alt="preview"
              className="w-full h-full object-cover rounded-lg cursor-pointer"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                setFaceImage({});
              }}
              className="absolute top-0 right-0 rounded-full p-1"
            >
              <X color="red" size={16} />
            </button>
          </div>
        </div>
      ) : (
        <ImageSection
          title={sourceTitle}
          images={images}
          selectedImage={faceImage}
          onImageSelect={handleFaceImageSelect}
          onImageUpload={handleFaceImageUpload}
          onReset={() => setFaceImage({})}
          renderLoadingPlaceholders={renderLoadingPlaceholders}
          type="source"
        />
      )}

      {/* Prop Section */}
      <div className="border-b border-[#484848] pb-2"></div>
      <div
        className="flex items-center justify-between cursor-pointer my-4 rounded-lg p-2"
        onClick={() => setPropsOpen(!isPropsOpen)}
      >
        <span className="text-white">Add Prop</span>
        {isPropsOpen ? (
          <ChevronUp color="white" />
        ) : (
          <ChevronDown color="white" />
        )}
      </div>

      {isPropsOpen && (
        <div className="p-4 bg-[#333537] text-white rounded-lg">
          <h3 className="text-lg font-medium">Select Area to add Prop</h3>
          <ToggleButton
            options={[
              { label: "Brush", value: "Brush", icon: <Brush size={16} /> },
              {
                label: "Click",
                value: "Click",
                icon: <MousePointerClick size={16} />,
              },
            ]}
            mode={mode}
            setMode={setMode}
          />
          <BrushSizeSlider brushSize={brushSize} setBrushSize={setBrushSize} />

          <h4 className="text-base">Describe the Prop:</h4>
          <textarea
            className="w-full h-24 mt-4 p-2 bg-[#2B2B2B] text-white rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-purple-500"
            placeholder="Type your Prompt here"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <div className="flex justify-between mt-6">
            <button className="px-10 py-4 border rounded-lg hover:bg-gray-600">
              Reset
            </button>
            <button className="px-10 py-4 bg-indigo-600 rounded-lg hover:bg-indigo-700">
              Done
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export const ObjectRemove = ({
  sourceTitle,
  faceImage,
  setFaceImage,
  images,
  handleFaceImageSelect,
  handleFaceImageUpload,
  renderLoadingPlaceholders,
  isPropsOpen,
  setPropsOpen,
  mode,
  setMode,
  brushSize,
  setBrushSize,
  openFullscreenImage,
}) => {
  return (
    <>
      {faceImage?.newImageUrl ? (
        <div className="ml-4 relative">
          <h1 className="text-white my-4">{sourceTitle}</h1>
          <div
            className="w-[150px] h-[150px] relative bg-grayBG2 rounded-lg mb-4"
            onClick={() => openFullscreenImage(faceImage.newImageUrl)}
          >
            <img
              src={faceImage.newImageUrl}
              alt="preview"
              className="w-full h-full object-cover rounded-lg cursor-pointer"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                setFaceImage({});
              }}
              className="absolute top-0 right-0 rounded-full p-1"
            >
              <X color="red" size={16} />
            </button>
          </div>
        </div>
      ) : (
        <ImageSection
          title={sourceTitle}
          images={images}
          selectedImage={faceImage}
          onImageSelect={handleFaceImageSelect}
          onImageUpload={handleFaceImageUpload}
          onReset={() => setFaceImage({})}
          renderLoadingPlaceholders={renderLoadingPlaceholders}
          type="source"
        />
      )}

      {/* Divider */}
      <div className="border-b border-[#484848] pb-2"></div>

      {/* Toggle for Object Removal */}
      <div
        className="flex items-center justify-between cursor-pointer my-4 rounded-lg p-2"
        onClick={() => setPropsOpen(!isPropsOpen)}
      >
        <span className="text-white">Select Object to remove</span>
        {isPropsOpen ? (
          <ChevronUp color="white" />
        ) : (
          <ChevronDown color="white" />
        )}
      </div>

      {isPropsOpen && (
        <div className="p-6 bg-[#333537] text-white rounded-lg">
          <h3 className="text-lg font-medium">Select Object to remove</h3>

          {/* Toggle Between Brush & Click */}
          <ToggleButton
            options={[
              {
                label: "Brush",
                value: "Brush",
                icon: <Brush size={16} />,
              },
              {
                label: "Click",
                value: "Click",
                icon: <MousePointerClick size={16} />,
              },
            ]}
            mode={mode}
            setMode={setMode}
          />

          {/* Brush Size Slider */}
          <BrushSizeSlider brushSize={brushSize} setBrushSize={setBrushSize} />

          {/* Buttons */}
          <div className="flex justify-between mt-6">
            <button className="px-10 py-4 border rounded-lg hover:bg-gray-600">
              Reset
            </button>
            <button className="px-10 py-4 bg-indigo-600 rounded-lg hover:bg-indigo-700">
              Done
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export const ProductTryon = ({
  sourceTitle,
  targetTitle,
  faceImage,
  setFaceImage,
  modelImage,
  setModelImage,
  images,
  modelImages,
  handleFaceImageSelect,
  handleFaceImageUpload,
  handleModelImageSelect,
  handleModelImageUpload,
  renderLoadingPlaceholders,
  openFullscreenImage,
}) => {
  const [isEditMaskingOpen, setIsEditMaskingOpen] = useState(false);
  const [isAreaSelected, setIsAreaSelected] = useState(false);
  const [mode, setMode] = useState("Brush");
  const [brushSize, setBrushSize] = useState(10);

  return (
    <div className="">
      {/* Source Image Section */}
      {faceImage?.newImageUrl ? (
        <div className="ml-4 relative">
          <h1 className="text-white my-4">{sourceTitle}</h1>
          <div
            className="w-[150px] h-[150px] relative bg-grayBG2 rounded-lg mb-4"
            onClick={() => openFullscreenImage(faceImage.newImageUrl)}
          >
            <img
              src={faceImage.newImageUrl}
              alt="preview"
              className="w-full h-full object-cover rounded-lg cursor-pointer"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                setFaceImage({});
              }}
              className="absolute top-0 right-0 rounded-full p-1"
            >
              <X color="red" size={16} />
            </button>
          </div>
          <div
            className="flex items-center justify-between cursor-pointer my-4 rounded-lg"
            onClick={() => setIsEditMaskingOpen(!isEditMaskingOpen)}
          >
            <span className="text-white">Edit Masking</span>
            {isEditMaskingOpen ? (
              <ChevronUp color="white" />
            ) : (
              <ChevronDown color="white" />
            )}
          </div>
          {isEditMaskingOpen && <EditMasking />}
        </div>
      ) : (
        <ImageSection
          title={sourceTitle}
          images={images}
          selectedImage={faceImage}
          onImageSelect={handleFaceImageSelect}
          onImageUpload={handleFaceImageUpload}
          onReset={() => setFaceImage({})}
          renderLoadingPlaceholders={renderLoadingPlaceholders}
          type="source"
        />
      )}

      <div className="border-b border-[#484848] pb-2"></div>

      {/* Target Image Section */}
      {modelImage?.newImageUrl ? (
        <div className="ml-4 relative">
          <h1 className="text-white my-4">{targetTitle}</h1>
          <div
            className="w-[150px] h-[150px] relative bg-grayBG2 rounded-lg mb-4"
            onClick={() => openFullscreenImage(modelImage.newImageUrl)}
          >
            <img
              src={modelImage.newImageUrl}
              alt="preview"
              className="w-full h-full object-cover rounded-lg cursor-pointer"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                setModelImage({});
              }}
              className="absolute top-0 right-0 rounded-full p-1"
            >
              <X color="red" size={16} />
            </button>
          </div>
          <div
            className="flex items-center justify-between cursor-pointer my-4 rounded-lg"
            onClick={() => setIsAreaSelected(!isAreaSelected)}
          >
            <span className="text-white">Select Area to add Product</span>
            {isAreaSelected ? (
              <ChevronUp color="white" />
            ) : (
              <ChevronDown color="white" />
            )}
          </div>
          {isAreaSelected && (
            <div className="p-4 bg-[#333537] text-white rounded-lg">
              <h3 className="text-lg font-medium">Select Area</h3>
              <ToggleButton
                options={[
                  { label: "Brush", value: "Brush", icon: <Brush size={16} /> },
                  {
                    label: "Click",
                    value: "Click",
                    icon: <MousePointerClick size={16} />,
                  },
                ]}
                mode={mode}
                setMode={setMode}
              />
              <BrushSizeSlider
                brushSize={brushSize}
                setBrushSize={setBrushSize}
              />
              <div className="flex justify-between mt-6">
                <button className="px-10 py-4 border rounded-lg hover:bg-gray-600">
                  Reset
                </button>
                <button className="px-10 py-4 bg-indigo-600 rounded-lg hover:bg-indigo-700">
                  Done
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <ImageSection
          title={targetTitle}
          images={modelImages}
          selectedImage={modelImage}
          onImageSelect={handleModelImageSelect}
          onImageUpload={handleModelImageUpload}
          onReset={() => setModelImage({})}
          renderLoadingPlaceholders={renderLoadingPlaceholders}
          type="target"
        />
      )}
    </div>
  );
};

export const BackgroundFill = ({
  modelImage,
  setModelImage,
  handleModelImageUpload,
  handleModelReset,
  predefinedPrompts,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [mode, setMode] = useState("Manual");
  const [prompt, setPrompt] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState("");

  return (
    <div className="mx-3">
      <div>
        <div className="text-white py-2 text-xl font-medium">Target Image</div>
        {modelImage.newImageUrl ? (
          <div className="relative w-[150px] h-[150px]">
            <img
              src={modelImage.newImageUrl}
              alt="preview"
              className="w-full h-full object-contain rounded-lg"
            />
            <button
              onClick={handleModelReset}
              className="absolute -top-1 right-2 text-black p-1 rounded-full text-sm z-10"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        ) : (
          <FileUploader
            title="Upload"
            handleImageUpload={handleModelImageUpload}
          />
        )}
      </div>

      <div className="mt-6 mb-6 w-full h-[1px] bg-gray-400"></div>

      {/* Background Creation Section */}
      <div className="p-2 bg-[#333537] text-white rounded-xl">
        <div className="flex items-center justify-between">
          <span className="text-lg font-medium">Create a Background</span>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-white hover:text-gray-300"
          >
            {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
        </div>

        {isExpanded && (
          <div>
            <ToggleButton
              options={[
                { label: "Manual", value: "Manual" },
                { label: "Predefined", value: "Predefined" },
              ]}
              mode={mode}
              setMode={setMode}
            />

            {mode === "Manual" ? (
              <textarea
                className="w-full h-24 p-2 bg-gray-700 text-white rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="Type your Prompt here"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
            ) : (
              <div className="relative">
                <select
                  className="w-full p-2 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  value={selectedPrompt}
                  onChange={(e) => setSelectedPrompt(e.target.value)}
                >
                  <option value="" disabled>
                    Select Prompt
                  </option>
                  {predefinedPrompts.map((prompt, index) => (
                    <option key={index} value={prompt}>
                      {prompt}
                    </option>
                  ))}
                </select>
                <textarea
                  className="w-full h-24 mt-4 p-2 bg-gray-700 text-white rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-purple-500"
                  placeholder="Type your Prompt here"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
              </div>
            )}

            <button className="w-full mt-4 bg-purple-600 hover:bg-purple-700 text-white py-2 rounded-md">
              Generate
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
