import { Upload } from "lucide-react";
import React, { useState, useRef } from "react";

const PhotoLibraryBG = ({
  images,
  handleFaceImageSelect,
  renderLoadingPlaceholders,
  handleReset,
  handleImageUpload,
}) => {
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFace, setSelectedFace] = useState(null);
  const [highlightedFace, setHighlightedFace] = useState(null);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      console.error("No files selected");
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => setPreview(e.target.result);
    reader.readAsDataURL(file);
    handleImageUpload(e);
  };

  const handleClick = () => {
    handleFaceImageSelect(preview);
    fileInputRef.current?.click();
  };

  return (
    <div className="rounded-lg p-2">
      <div
        className="grid grid-cols-3 gap-2 overflow-y-auto"
        style={{
          maxHeight: "300px",
          scrollbarWidth: "thin",
          scrollbarColor: "#666 #222226",
        }}
      >
        <button
          onClick={handleClick}
          className="flex relative flex-col gap-2 rounded-lg bg-black text-white justify-center items-center"
        >
          {preview ? (
            <img
              src={preview}
              alt="Upload preview"
              className="w-[90px] h-[90px] object-cover border-2 border-yellow-400 rounded-lg"
              onClick={handleClick}
            />
          ) : (
            <>
              <Upload />
              <p className="text-[10px]">Upload Image</p>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="border absolute w-full h-full hidden"
              />
            </>
          )}
        </button>
        {images && images.length > 0 ? (
          images.map((img, index) => (
            <div
              key={index}
              className={`aspect-square w-[90px] h-[90px] relative rounded-lg overflow-hidden bg-[#2A2A2E] 
                ${highlightedFace === img ? "ring-2 ring-yellow-400" : ""}
                ${selectedFace === img ? "ring-2 ring-green-400" : ""}`}
            >
              <img
                src={img}
                className="w-full h-full object-cover cursor-pointer"
                alt={`Image ${index + 1}`}
                onClick={() => setHighlightedFace(img)}
              />
            </div>
          ))
        ) : (
          <>{renderLoadingPlaceholders()}</>
        )}
      </div>
      <div className="mt-4 flex text-white items-center gap-4 justify-between">
        <button
          onClick={() => {
            handleReset();
            setPreview(null);
            setHighlightedFace(null);
            setSelectedFace(null);
          }}
          className="w-full py-4 rounded-lg bg-grayBG2"
        >
          Reset
        </button>
        <button
          onClick={() => {
            handleFaceImageSelect(highlightedFace);
            setSelectedFace(highlightedFace);
          }}
          disabled={!highlightedFace}
          className={`w-full py-4 rounded-lg ${
            !highlightedFace ? "bg-grayBG2" : "bg-generate-gradient"
          }`}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default PhotoLibraryBG;
